{
  "name": "ProjectKB Alice",
  "vendorId": "0x0159",
  "productId": "0xA71C",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes", "via/qmk_lighting"],
  "menus": [
    "via/keymap",
    "via/layouts",
    "via/macros",
    "via/save_load",
    "core/qmk_backlight",
    "core/qmk_rgblight"
  ],
  "matrix": {"rows": 5, "cols": 16},
  "layouts": {
    "labels": ["Long Backspace", "Long RShift"],
    "keymap": [
      [{"y": 0.15, "x": 15.15, "c": "#aaaaaa", "w": 2}, "0,15\n\n\n0,1"],
      [{"x": 0.55, "c": "#777777"}, "0,0"],
      [{"y": -0.95, "x": 3.7, "c": "#cccccc"}, "0,3", {"x": 8.45}, "0,12"],
      [
        {"y": -0.9500000000000002, "x": 1.7},
        "0,1",
        "0,2",
        {"x": 10.45},
        "0,13",
        {"x": 1.7763568394002505e-15},
        "0,14\n\n\n0,0",
        {"c": "#aaaaaa"},
        "0,15\n\n\n0,0"
      ],
      [{"y": -0.10000000000000009, "x": 0.35}, "1,0"],
      [{"y": -0.9499999999999997, "x": 13, "c": "#cccccc"}, "1,12"],
      [
        {"y": -0.9500000000000002, "x": 1.5, "c": "#aaaaaa", "w": 1.5},
        "1,1",
        {"c": "#cccccc"},
        "1,2",
        {"x": 10},
        "1,13",
        "1,14",
        {"c": "#aaaaaa", "w": 1.5},
        "1,15"
      ],
      [{"y": -0.10000000000000009, "x": 0.15}, "2,0"],
      [
        {"y": -0.9000000000000004, "x": 13.4, "c": "#cccccc"},
        "2,12",
        "2,13",
        {"c": "#777777", "w": 2.25},
        "2,15"
      ],
      [
        {"y": -0.9999999999999996, "x": 1.3, "c": "#aaaaaa", "w": 1.75},
        "2,1",
        {"c": "#cccccc"},
        "2,2"
      ],
      [
        {"x": 1.05, "c": "#aaaaaa", "w": 2.25},
        "3,1",
        {"c": "#cccccc"},
        "3,2",
        {"x": 8.8},
        "3,12",
        {"x": -1.7763568394002505e-15},
        "3,13",
        {"c": "#aaaaaa", "w": 1.75},
        "3,14\n\n\n1,0",
        "3,15\n\n\n1,0",
        {"x": 0.29999999999999716, "w": 2.75},
        "3,14\n\n\n1,1"
      ],
      [{"x": 1.05, "w": 1.5}, "4,1", {"x": 13.45, "w": 1.5}, "4,15"],
      [
        {"r": 12, "y": -6, "x": 5.05, "c": "#cccccc"},
        "0,4",
        "0,5",
        "0,6",
        "0,7"
      ],
      [{"x": 4.6}, "1,3", "1,4", "1,5", "1,6"],
      [{"x": 4.85}, "2,3", "2,4", "2,5", "2,6"],
      [{"x": 5.3}, "3,3", "3,4", "3,5", "3,6"],
      [
        {"x": 6.6, "c": "#777777", "w": 2},
        "4,4",
        {"c": "#aaaaaa", "w": 1.25},
        "4,6"
      ],
      [{"y": -0.9500000000000002, "x": 5.05, "w": 1.5}, "4,3"],
      [
        {"r": -12, "y": -1.4499999999999997, "x": 8.45, "c": "#cccccc"},
        "0,8",
        "0,9",
        "0,10",
        "0,11"
      ],
      [{"x": 8.05}, "1,8", "1,9", "1,10", "1,11"],
      [{"x": 8.2}, "2,8", "2,9", "2,10", "2,11"],
      [{"x": 7.75}, "3,8", "3,9", "3,10", "3,11"],
      [{"x": 7.75, "c": "#777777", "w": 2.75}, "4,9"],
      [{"y": -0.9499999999999993, "x": 10.55, "c": "#aaaaaa", "w": 1.5}, "4,11"]
    ]
  }
}