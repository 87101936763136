[
    {
        "name": "corne",
        "plate": true
    },
    [
        {
            "y": 0.2,
            "x": 3,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "3\n\nF3\n\n\n\n#\n\n\nE",
        {
            "x": 7
        },
        "8\n\nF8\n\n\n\n*\n\n\nI"
    ],
    [
        {
            "y": -0.85,
            "x": 2
        },
        "2\n\nF2\n\n\n\n@\n\n\nW",
        {
            "x": 1
        },
        "4\n\nF4\n\n\n\n$\n\n\nR",
        {
            "x": 5
        },
        "7\n\nF7\n\n\n\n&\n\n\nU",
        {
            "x": 1
        },
        "9\n\nF9\n\n\n\n(\n\n\nO"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 5
        },
        "5\n\nF5\n\n\n\n%\n\n\nT",
        {
            "x": 3
        },
        "6\n\nF6\n\n\n\n^\n\n\nY"
    ],
    [
        {
            "y": -0.9
        },
        "Esc\n\nF12\n\n\n\n\n\n\nTab",
        "1\n\nF1\n\n\n\n!\n\n\nQ",
        {
            "x": 11
        },
        "0\n\nF10\n\n\n\n)\n\n\nP",
        "Del\n\nF11\n\n\n\n\n\n\nBsp"
    ],
    [
        {
            "y": -0.40000000000000013,
            "x": 3,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "D",
        {
            "x": 7,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Home\n\n=\n\n\n\n\n+\n\nK"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 2,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "S",
        {
            "x": 1,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "n": true
        },
        "\n\nO_BLE\n\n\n\n\n\n\nF",
        {
            "x": 5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "n": true
        },
        "\n\n-\n\n\n\n\n_\n\nJ",
        {
            "x": 1,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Up\n\n\\\n\n\n\n\n|\n\nL"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111"
        },
        "\n\nO_USB\n\n\n\n\n\n\nG",
        {
            "x": 3,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "H"
    ],
    [
        {
            "y": -0.8999999999999999,
            "c": "#cdcdcd"
        },
        "Ctrl",
        {
            "c": "#cccccc",
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\nK_PWR\n\n\n\n\n\n\nA",
        {
            "x": 11,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "End\n\n`\n\n\n\n\n~\n\n;\n:",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n\"\n\n\n\n\n'"
    ],
    [
        {
            "y": -0.3999999999999999,
            "x": 3,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "\n\nBT_2\n\n\n\n\n\n\nC",
        {
            "x": 7,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111"
        },
        "Left\n\n]\n\n\n\n\n}\n\n,\n<"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 2,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111"
        },
        "\n\nBT_1\n\n\n\n\n\n\nX",
        {
            "x": 1
        },
        "\n\nBT_3\n\n\n\n\n\n\nV",
        {
            "x": 5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111"
        },
        "\n\n[\n\n\n\n\n{\n\nM",
        {
            "x": 1,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Down\n\n\n\n\n\n\n\n\n.\n>"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111"
        },
        "\n\nBT_CLR\n\n\n\n\n\n\nB",
        {
            "x": 3,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "N"
    ],
    [
        {
            "y": -0.8999999999999999,
            "c": "#cdcdcd"
        },
        "Shift",
        {
            "c": "#cccccc",
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\nBT_0\n\n\n\n\n\n\nZ",
        {
            "x": 11,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Right\n\n\n\n\n\n\n\n\n/\n?",
        {
            "c": "#8c94ff",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Lower"
    ],
    [
        {
            "y": -0.050000000000000266,
            "x": 3.5,
            "c": "#cdcdcd"
        },
        "Alt",
        {
            "x": 6
        },
        "Super"
    ],
    [
        {
            "r": 15,
            "y": -2.3,
            "x": 5.55,
            "c": "#8c94ff",
            "n": true
        },
        "Lower"
    ],
    [
        {
            "r": 30,
            "y": -3.25,
            "x": 7.2,
            "c": "#cdcdcd",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "h": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nSpace"
    ],
    [
        {
            "r": -30,
            "y": 6.45,
            "x": 4.8,
            "h": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nEnter"
    ],
    [
        {
            "r": -15,
            "y": -2.3499999999999996,
            "x": 7.95,
            "c": "#ff7575",
            "a": 7,
            "n": true
        },
        "Raise"
    ]
]
