[
    {
        "name": "Saturn60"
    },
    [
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "`\n\n\n\n\n\n~\n\n\nEsc",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "F1\n\n\n\n\n\n\n\n\n1\n!",
        "F2\n\n\n\n\n\n\n\n\n2\n@",
        "F3\n\n\n\n\n\n\n\n\n3\n#",
        "F4\n\n\n\n\n\n\n\n\n4\n$",
        "F5\n\n\n\n\n\n\n\n\n5\n%",
        "F6\n\n\n\n\n\n\n\n\n6\n^",
        "F7\n\n\n\n\n\n\n\n\n7\n&",
        "F8\n\n\n\n\n\n\n\n\n8\n*",
        "F9\n\n\n\n\n\n\n\n\n9\n(",
        "F10\n\n\n\n\n\n\n\n\n0\n)",
        "F11\n\n\n\n\n\n\n\n\n-\n_",
        "F12\n\n\n\n\n\n\n\n\n=\n+",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "w": 2
        },
        "Del\n\n\n\n\n\n\n\n\nBackspace"
    ],
    [
        {
            "t": "#111111",
            "a": 7,
            "fa": [3],
            "w": 1.5
        },
        "Tab",
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Up\n\n\n\n\n\n\n\n\n[\n{",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n}\n\n\n\n\n]",
        {
            "w": 1.5
        },
        "\n|\n\n\n\n\n\\"
    ],
    [
        {
            "c": "#cdcdcd",
            "a": 7,
            "fa": [3],
            "w": 1.25,
            "w2": 1.75,
            "l": true
        },
        "Ctrl",
        {
            "x": 0.5,
            "c": "#cccccc"
        },
        "A",
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G",
        "H",
        {
            "n": true
        },
        "J",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Home\n\n\n\n\n\n\n\n\nK",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "L",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Left\n\n\n\n\n\n\n\n\n;\n:",
        "Right\n\n\n\n\n\n\n\n\n'\n\"",
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "fa": [3],
            "w": 2.25
        },
        "Enter"
    ],
    [
        {
            "w": 2.25
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "End\n\n\n\n\n\n\n\n\n,\n<",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n>\n\n\n\n\n.",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "Down\n\n\n\n\n\n\n\n\n/\n?",
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "fa": [3],
            "w": 1.75
        },
        "Shift",
        {
            "c": "#8c94ff"
        },
        "Fn"
    ],
    [
        {
            "c": "#cdcdcd",
            "w": 1.5
        },
        "",
        "Super",
        {
            "w": 1.5
        },
        "Alt",
        {
            "w": 7
        },
        "Space",
        {
            "w": 1.5
        },
        "Alt",
        "Super",
        {
            "w": 1.5
        },
        ""
    ]
]
