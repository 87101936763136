[
    {
        "name": "alix40",
        "plate": true
    },
    [
        {
            "y": 0.3,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Esc\n\nF12\n\n\n\n\n\n\nTab",
        "1\n\nF1\n\n\n\n!\n\n\nQ",
        {
            "x": 9.4
        },
        "0\n\nF10\n\n\n\n)\n\n\nP",
        "Del\n\nF11\n\n\n\n\n\n\nBsp"
    ],
    [
        {
            "x": 0.1,
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Ctrl",
        {
            "c": "#cccccc"
        },
        "A",
        {
            "x": 9.6,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\n`\n\n\n\n\n~\n\n;\n:",
        {
            "t": "#111111",
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n\"\n\n\n\n\n'"
    ],
    [
        {
            "x": 0.4,
            "c": "#cdcdcd",
            "a": 7
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Z",
        {
            "x": 9,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "Right\n\n\n\n\n\n\n\n\n.\n>",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5
        },
        "\n?\n\n\n\n\n/"
    ],
    [
        {
            "x": 0.2,
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Super",
        "Alt",
        {
            "x": 9.399999999999999,
            "c": "#cccccc",
            "f": 3
        },
        "",
        {
            "x": 1.7763568394002505e-15,
            "c": "#8c94ff",
            "f": 2,
            "fa": [3]
        },
        "Lower"
    ],
    [
        {
            "r": 5,
            "y": -4.2,
            "x": 2.1,
            "c": "#cccccc",
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "2\n\nF2\n\n\n\n@\n\n\nW"
    ],
    [
        {
            "y": -0.9999999999999997,
            "x": 3.1
        },
        "3\n\nF3\n\n\n\n#\n\n\nE"
    ],
    [
        {
            "y": -1,
            "x": 4.1
        },
        "4\n\nF4\n\n\n\n$\n\n\nR",
        "5\n\nF5\n\n\n\n%\n\n\nT"
    ],
    [
        {
            "x": 2.3,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G"
    ],
    [
        {
            "x": 2.7
        },
        "X",
        "C",
        "V",
        "B"
    ],
    [
        {
            "x": 3.7,
            "c": "#8c94ff",
            "n": true
        },
        "Lower",
        {
            "c": "#cdcdcd",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 2
        },
        "\n\n\n2\n\n\n\n\n\nSpace"
    ],
    [
        {
            "r": -5,
            "y": -2.8,
            "x": 7.25,
            "c": "#cccccc",
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "6\n\nF6\n\n\n\n^\n\n\nY"
    ],
    [
        {
            "y": -0.9999999999999998,
            "x": 8.25
        },
        "7\n\nF7\n\n\n\n&\n\n\nU",
        "8\n\nF8\n\n\n\n*\n\n\nI",
        "9\n\nF9\n\n\n\n(\n\n\nO"
    ],
    [
        {
            "x": 7.45,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "H",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "n": true
        },
        "Home\n\n-\n\n\n\n\n_\n\nJ",
        "Up\n\n=\n\n\n\n\n+\n\nK",
        "End\n\n\\\n\n\n\n\n|\n\nL"
    ],
    [
        {
            "x": 7.1,
            "t": "#111111",
            "a": 7,
            "f": 3
        },
        "",
        {
            "t": "#111111\n\n#ff0000\n\n\n\n\n#ff0000",
            "a": 4,
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\n[\n\n\n\n\n{\n\nN",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111"
        },
        "Left\n\n]\n\n\n\n\n}\n\nM",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Down\n\n\n\n\n\n\n\n\n,\n<"
    ],
    [
        {
            "x": 7.1,
            "c": "#cdcdcd",
            "t": "#111111",
            "w": 2
        },
        "\n\n\n2\n\n\n\n\n\nEnter",
        {
            "c": "#ff7575",
            "a": 7,
            "fa": [3],
            "n": true
        },
        "Raise"
    ]
]
