[
    {
        "name": "ID80"
    },
    [
        {
            "a": 7,
            "f": 2,
            "fa": [3]
        },
        "Esc",
        {
            "x": 0.25
        },
        "F1",
        "F2",
        "F3",
        "F4",
        {
            "x": 0.25
        },
        "F5",
        "F6",
        "F7",
        "F8",
        {
            "x": 0.25
        },
        "F9",
        "F10",
        "F11",
        "F12",
        {
            "x": 0.25
        },
        "",
        {
            "x": 0.25
        },
        "Delete"
    ],
    [
        {
            "y": 0.25,
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n~\n\n\n\n\n`",
        "\n!\n\n\n\n\n1",
        "\n@\n\n\n\n\n2",
        "\n#\n\n\n\n\n3",
        "\n$\n\n\n\n\n4",
        "\n%\n\n\n\n\n5",
        "\n^\n\n\n\n\n6",
        "\n&\n\n\n\n\n7",
        "\n*\n\n\n\n\n8",
        "\n(\n\n\n\n\n9",
        "\n)\n\n\n\n\n0",
        "\n_\n\n\n\n\n-",
        "\n+\n\n\n\n\n=",
        {
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 3, 0, 0, 3],
            "w": 2
        },
        "\n\n\n2\n\n\n\n\n\nBackspace",
        {
            "x": 0.25,
            "a": 7
        },
        "Home"
    ],
    [
        {
            "a": 4,
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nTab",
        {
            "a": 7
        },
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        {
            "a": 5
        },
        "\n{\n\n\n\n\n[",
        "\n}\n\n\n\n\n]",
        {
            "a": 4,
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\n\\\n|",
        {
            "x": 0.25,
            "a": 7
        },
        "End"
    ],
    [
        {
            "a": 4,
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\nCaps Lock",
        {
            "a": 7
        },
        "A",
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G",
        "H",
        {
            "n": true
        },
        "J",
        "K",
        "L",
        {
            "a": 5
        },
        "\n:\n\n\n\n\n;",
        {
            "fa": [3, 3]
        },
        "\n\"\n\n\n\n\n'",
        {
            "a": 4,
            "fa": [3, 3, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 2.25
        },
        "\n\n\n2.25\n\n\n\n\n\nEnter"
    ],
    [
        {
            "w": 2.25
        },
        "\n\n\n2.25\n\n\n\n\n\nShift",
        {
            "a": 7
        },
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        {
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n<\n\n\n\n\n,",
        "\n>\n\n\n\n\n.",
        "\n?\n\n\n\n\n/",
        {
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 3, 0, 0, 3],
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\nShift"
    ],
    [
        {
            "y": -0.75,
            "x": 14.25,
            "a": 7
        },
        "Up"
    ],
    [
        {
            "y": -0.25,
            "a": 4,
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nCtrl",
        {
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nSuper",
        {
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nAlt",
        {
            "w": 6.25
        },
        "\n\n\n6.25\n\n\n\n\n\nSpace",
        {
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nAlt",
        {
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nCtrl"
    ],
    [
        {
            "y": -0.75,
            "x": 13.25,
            "a": 7
        },
        "Left",
        "Down",
        "Right"
    ]
]
