[
    {
        "name": "Discipline65"
    },
    [
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "`\n\n\n\n\n\n~\n\n\nEsc",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "F1\n\n\n\n\n\n\n\n\n1\n!",
        "F2\n\n\n\n\n\n\n\n\n2\n@",
        "F3\n\n\n\n\n\n\n\n\n3\n#",
        "F4\n\n\n\n\n\n\n\n\n4\n$",
        "F5\n\n\n\n\n\n\n\n\n5\n%",
        "F6\n\n\n\n\n\n\n\n\n6\n^",
        "F7\n\n\n\n\n\n\n\n\n7\n&",
        "F8\n\n\n\n\n\n\n\n\n8\n*",
        "F9\n\n\n\n\n\n\n\n\n9\n(",
        "F10\n\n\n\n\n\n\n\n\n0\n)",
        "F11\n\n\n\n\n\n\n\n\n-\n_",
        "F12\n\n\n\n\n\n\n\n\n=\n+",
        {
            "t": "#111111",
            "w": 2
        },
        "\n\n\n2\n\n\n\n\n\nBackspace",
        {
            "a": 7,
            "fa": [3]
        },
        "Delete"
    ],
    [
        {
            "t": "#0100ff\n\n\n#111111\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 1.5
        },
        "Caps\n\n\n1.5\n\n\n\n\n\nTab",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n{\n\n\n\n\n[",
        {
            "t": "#111111"
        },
        "\n}\n\n\n\n\n]",
        {
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 3, 0, 0, 3],
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\n\\\n|",
        {
            "a": 7
        },
        "Home"
    ],
    [
        {
            "c": "#cdcdcd",
            "a": 4,
            "w": 1.25,
            "w2": 1.75,
            "l": true
        },
        "\n\n\n1.75\n\n\n\n\n\nCtrl",
        {
            "x": 0.5,
            "c": "#cccccc",
            "a": 7
        },
        "A",
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G",
        "H",
        {
            "n": true
        },
        "J",
        "K",
        "L",
        {
            "a": 5
        },
        "\n:\n\n\n\n\n;",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "fa": [3, 3]
        },
        "\n\"\n\n\n\n\n'",
        {
            "t": "#111111",
            "a": 4,
            "fa": [3, 3, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 2.25
        },
        "\n\n\n2.25\n\n\n\n\n\nEnter",
        {
            "a": 7
        },
        "End"
    ],
    [
        {
            "c": "#cdcdcd",
            "a": 4,
            "w": 2.25
        },
        "\n\n\n2.25\n\n\n\n\n\nShift",
        {
            "c": "#cccccc",
            "a": 7
        },
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n<\n\n\n\n\n,",
        {
            "t": "#111111"
        },
        "\n>\n\n\n\n\n.",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111"
        },
        "\n?\n\n\n\n\n/",
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 3, 0, 0, 3],
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\nShift",
        {
            "c": "#cccccc",
            "a": 7
        },
        "Up",
        {
            "c": "#8c94ff"
        },
        "Fn"
    ],
    [
        {
            "c": "#cdcdcd",
            "a": 4,
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nSuper",
        {
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nAlt",
        {
            "w": 7
        },
        "\n\n\n7\n\n\n\n\n\nSpace",
        {
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nAlt",
        {
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nCtrl",
        {
            "c": "#cccccc",
            "a": 7
        },
        "Left",
        "Down",
        "Right"
    ]
]
