[
    {
        "name": "Nyquist",
        "plate": true
    },
    [
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F12\n\n\n\n\n\n\n\n\nEsc",
        "F1\n\n\n\n\n\n\n\n\n1\n!",
        "F2\n\n\n\n\n\n\n\n\n2\n@",
        "F3\n\n\n\n\n\n\n\n\n3\n#",
        "F4\n\n\n\n\n\n\n\n\n4\n$",
        "F5\n\n\n\n\n\n\n\n\n5\n%",
        {
            "x": 1
        },
        "F6\n\n\n\n\n\n\n\n\n6\n^",
        "F7\n\n\n\n\n\n\n\n\n7\n&",
        "F8\n\n\n\n\n\n\n\n\n8\n*",
        "F9\n\n\n\n\n\n\n\n\n9\n(",
        "F10\n\n\n\n\n\n\n\n\n0\n)",
        "F11\n\n\n\n\n\n\n\n\nDel"
    ],
    [
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Tab",
        "Q",
        "W",
        "E",
        "R",
        "T",
        {
            "x": 1
        },
        "Y",
        "U",
        "I",
        "O",
        "P",
        "Bsp"
    ],
    [
        {
            "c": "#cdcdcd"
        },
        "Ctrl",
        {
            "c": "#cccccc"
        },
        "A",
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G",
        {
            "x": 1
        },
        "H",
        {
            "t": "#0100ff\n\n\n\n\n\n#0100ff\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "n": true
        },
        "-\n\n\n\n\n\n_\n\n\nJ",
        "=\n\n\n\n\n\n+\n\n\nK",
        "\\\n\n\n\n\n\n|\n\n\nL",
        {
            "t": "#0100ff\n\n\n\n\n\n#0100ff\n\n\n#111111\n#111111"
        },
        "`\n\n\n\n\n\n~\n\n\n;\n:",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n\"\n\n\n\n\n'"
    ],
    [
        {
            "c": "#cdcdcd",
            "a": 7,
            "fa": [3]
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Z",
        "X",
        "C",
        "V",
        "B",
        {
            "x": 1
        },
        "N",
        {
            "t": "#0100ff\n\n\n\n\n\n#0100ff\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "[\n\n\n\n\n\n{\n\n\nM",
        {
            "t": "#0100ff\n\n\n\n\n\n#0100ff\n\n\n#111111\n#111111"
        },
        "]\n\n\n\n\n\n}\n\n\n,\n<",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n>\n\n\n\n\n.",
        {
            "t": "#111111",
            "a": 7,
            "f": 3
        },
        "Up",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n?\n\n\n\n\n/"
    ],
    [
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Ctrl",
        "Alt",
        "Super",
        {
            "c": "#8c94ff"
        },
        "Fn",
        {
            "c": "#cdcdcd",
            "w": 2
        },
        "Space",
        {
            "x": 1,
            "w": 2
        },
        "Enter",
        {
            "c": "#8c94ff"
        },
        "Fn",
        {
            "c": "#cccccc",
            "f": 3
        },
        "Left",
        "Down",
        "Right"
    ]
]
