[
    {
        "name": "HHKB"
    },
    [
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Power\n\n\n\n\n\n\n\n\nEsc",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "F1\n\n\n\n\n\n\n\n\n1\n!",
        "F2\n\n\n\n\n\n\n\n\n2\n@",
        "F3\n\n\n\n\n\n\n\n\n3\n#",
        "F4\n\n\n\n\n\n\n\n\n4\n$",
        "F5\n\n\n\n\n\n\n\n\n5\n%",
        "F6\n\n\n\n\n\n\n\n\n6\n^",
        "F7\n\n\n\n\n\n\n\n\n7\n&",
        "F8\n\n\n\n\n\n\n\n\n8\n*",
        "F9\n\n\n\n\n\n\n\n\n9\n(",
        "F10\n\n\n\n\n\n\n\n\n0\n)",
        "F11\n\n\n\n\n\n\n\n\n-\n_",
        "F12\n\n\n\n\n\n\n\n\n=\n+",
        "Ins\n\n\n\n\n\n\n\n\n\\\n|",
        "Del\n\n\n\n\n\n\n\n\n`\n~"
    ],
    [
        {
            "t": "#0100ff\n\n\n#111111\n\n\n\n\n\n#111111",
            "w": 1.5
        },
        "Caps\n\n\n1.5\n\n\n\n\n\nTab",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "PSc/SRq\n\n\n\n\n\n\n\n\nI",
        "ScrLk\n\n\n\n\n\n\n\n\nO",
        "Pus/Brk\n\n\n\n\n\n\n\n\nP",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Up\n\n\n\n\n\n\n\n\n[\n{",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n}\n\n\n\n\n]",
        {
            "t": "#0100ff\n\n\n#111111\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3],
            "w": 1.5
        },
        "NumLk\n\n\n1.5\n\n\n\n\n\nBackspace"
    ],
    [
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\nCtrl",
        {
            "c": "#cccccc",
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "Vol_Dn\n\n\n\n\n\n\n\n\nA",
        "Vol_Up\n\n\n\n\n\n\n\n\nS",
        "Mute\n\n\n\n\n\n\n\n\nD",
        {
            "n": true
        },
        "Eject\n\n\n\n\n\n\n\n\nF",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "G",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "*\n\n\n\n\n\n\n\n\nH",
        {
            "n": true
        },
        "/\n\n\n\n\n\n\n\n\nJ",
        "Home\n\n\n\n\n\n\n\n\nK",
        "PgUp\n\n\n\n\n\n\n\n\nL",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Left\n\n\n\n\n\n\n\n\n;\n:",
        {
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Right\n\n\n\n\n\n\n\n\n'\n\"",
        {
            "c": "#cdcdcd",
            "t": "#0100ff\n\n\n#111111\n\n\n\n\n\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 2.25
        },
        "NumEnter\n\n\n2.25\n\n\n\n\n\nEnter"
    ],
    [
        {
            "t": "#111111",
            "w": 2.25
        },
        "\n\n\n2.25\n\n\n\n\n\nShift",
        {
            "c": "#cccccc",
            "a": 7,
            "fa": [3]
        },
        "Z",
        "X",
        "C",
        "V",
        "B",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "+\n\n\n\n\n\n\n\n\nN",
        "-\n\n\n\n\n\n\n\n\nM",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "End\n\n\n\n\n\n\n\n\n,\n<",
        "PgDn\n\n\n\n\n\n\n\n\n.\n>",
        "Down\n\n\n\n\n\n\n\n\n/\n?",
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\nShift",
        {
            "c": "#8c94ff",
            "a": 7,
            "fa": [3]
        },
        "Fn"
    ],
    [
        {
            "x": 1.5,
            "c": "#cdcdcd"
        },
        "Super",
        {
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nAlt",
        {
            "w": 6
        },
        "\n\n\n6\n\n\n\n\n\nSpace",
        {
            "w": 1.5
        },
        "\n\n\n1.5\n\n\n\n\n\nAlt",
        {
            "a": 7
        },
        "Super"
    ]
]
