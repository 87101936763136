{
  "name": "DZ60",
  "vendorId": "0x445A",
  "productId": "0x2260",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes", "via/qmk_lighting"],
  "menus": [
    "via/keymap",
    "via/layouts",
    "via/macros",
    "via/save_load",
    "core/qmk_backlight",
    "core/qmk_rgblight"
  ],
  "matrix": {"rows": 5, "cols": 15},
  "layouts": {
    "presets": {
      "Default": [0, 0, 0, 0],
      "ISO": [0, 1, 3, 0],
      "HHKB": [0, 0, 0, 2],
      "With arrows": [0, 0, 3, 3]
    },
    "labels": [
      "Split Backspace",
      "ISO Enter",
      [
        "Row 4",
        "Default",
        "Split right shift",
        "Arrows",
        "Default ISO",
        "ISO split right shift",
        "ISO arrows",
        "[2U] Default",
        "[2U] Split left shift",
        "[2U] Split right shift",
        "[2U] Split both shifts"
      ],
      [
        "Bottom row",
        "6.25U",
        "7U",
        "6.25U Arrows",
        "6.25U Arrows B",
        "7U Arrows",
        "Split space",
        "Split space arrows"
      ]
    ],
    "keymap": [
      [
        {"x": 2.5, "c": "#777777"},
        "0,0",
        {"c": "#cccccc"},
        "0,1",
        "0,2",
        "0,3",
        "0,4",
        "0,5",
        "0,6",
        "0,7",
        "0,8",
        "0,9",
        "0,10",
        "0,11",
        "0,12",
        {"c": "#aaaaaa", "w": 2},
        "0,14\n\n\n0,0",
        {"x": 0.5, "c": "#cccccc"},
        "0,13\n\n\n0,1",
        "0,14\n\n\n0,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.5},
        "1,0",
        {"c": "#cccccc"},
        "1,2",
        "1,3",
        "1,4",
        "1,5",
        "1,6",
        "1,7",
        "1,8",
        "1,9",
        "1,10",
        "1,11",
        "1,12",
        "1,13",
        {"w": 1.5},
        "1,14\n\n\n1,0",
        {
          "x": 1.25,
          "c": "#777777",
          "w": 1.25,
          "h": 2,
          "w2": 1.5,
          "h2": 1,
          "x2": -0.25
        },
        "2,13\n\n\n1,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.75},
        "2,0",
        {"c": "#cccccc"},
        "2,2",
        "2,3",
        "2,4",
        "2,5",
        "2,6",
        "2,7",
        "2,8",
        "2,9",
        "2,10",
        "2,11",
        "2,12",
        {"c": "#777777", "w": 2.25},
        "2,13\n\n\n1,0",
        {"x": 0.25, "c": "#cccccc"},
        "1,14\n\n\n1,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 2.25},
        "3,0\n\n\n2,0",
        {"c": "#cccccc"},
        "3,2\n\n\n2,0",
        "3,3\n\n\n2,0",
        "3,4\n\n\n2,0",
        "3,5\n\n\n2,0",
        "3,6\n\n\n2,0",
        "3,7\n\n\n2,0",
        "3,8\n\n\n2,0",
        "3,9\n\n\n2,0",
        "3,10\n\n\n2,0",
        "3,11\n\n\n2,0",
        {"c": "#aaaaaa", "w": 2.75},
        "3,13\n\n\n2,0"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "4,0\n\n\n3,0",
        {"w": 1.25},
        "4,1\n\n\n3,0",
        {"w": 1.25},
        "4,3\n\n\n3,0",
        {"c": "#cccccc", "w": 6.25},
        "4,6\n\n\n3,0",
        {"c": "#aaaaaa", "w": 1.25},
        "4,10\n\n\n3,0",
        {"w": 1.25},
        "4,11\n\n\n3,0",
        {"w": 1.25},
        "4,13\n\n\n3,0",
        {"w": 1.25},
        "4,14\n\n\n3,0"
      ],
      [
        {"y": 0.5, "x": 2.5, "w": 2.25},
        "3,0\n\n\n2,1",
        {"c": "#cccccc"},
        "3,2\n\n\n2,1",
        "3,3\n\n\n2,1",
        "3,4\n\n\n2,1",
        "3,5\n\n\n2,1",
        "3,6\n\n\n2,1",
        "3,7\n\n\n2,1",
        "3,8\n\n\n2,1",
        "3,9\n\n\n2,1",
        "3,10\n\n\n2,1",
        "3,11\n\n\n2,1",
        {"c": "#aaaaaa", "w": 1.75},
        "3,13\n\n\n2,1",
        "3,14\n\n\n2,1"
      ],
      [
        {"x": 2.5, "w": 2.25},
        "3,0\n\n\n2,2",
        {"c": "#cccccc"},
        "3,2\n\n\n2,2",
        "3,3\n\n\n2,2",
        "3,4\n\n\n2,2",
        "3,5\n\n\n2,2",
        "3,6\n\n\n2,2",
        "3,7\n\n\n2,2",
        "3,8\n\n\n2,2",
        "3,9\n\n\n2,2",
        "3,10\n\n\n2,2"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.25},
        "3,0\n\n\n2,3",
        "3,1\n\n\n2,3",
        {"c": "#cccccc"},
        "3,2\n\n\n2,3",
        "3,3\n\n\n2,3",
        "3,4\n\n\n2,3",
        "3,5\n\n\n2,3",
        "3,6\n\n\n2,3",
        "3,7\n\n\n2,3",
        "3,8\n\n\n2,3",
        "3,9\n\n\n2,3",
        "3,10\n\n\n2,3",
        "3,11\n\n\n2,3",
        {"c": "#aaaaaa", "w": 2.75},
        "3,13\n\n\n2,3"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "3,0\n\n\n2,4",
        "3,1\n\n\n2,4",
        {"c": "#cccccc"},
        "3,2\n\n\n2,4",
        "3,3\n\n\n2,4",
        "3,4\n\n\n2,4",
        "3,5\n\n\n2,4",
        "3,6\n\n\n2,4",
        "3,7\n\n\n2,4",
        "3,8\n\n\n2,4",
        "3,9\n\n\n2,4",
        "3,10\n\n\n2,4",
        "3,11\n\n\n2,4",
        {"c": "#aaaaaa", "w": 1.75},
        "3,13\n\n\n2,4",
        "3,14\n\n\n2,4"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "3,0\n\n\n2,5",
        "3,1\n\n\n2,5",
        {"c": "#cccccc"},
        "3,2\n\n\n2,5",
        "3,3\n\n\n2,5",
        "3,4\n\n\n2,5",
        "3,5\n\n\n2,5",
        "3,6\n\n\n2,5",
        "3,7\n\n\n2,5",
        "3,8\n\n\n2,5",
        "3,9\n\n\n2,5",
        "3,10\n\n\n2,5"
      ],
      [
        {"y": 0.25, "x": 2.5, "c": "#aaaaaa", "w": 2},
        "3,0\n\n\n2,6",
        {"c": "#cccccc"},
        "3,2\n\n\n2,6",
        "3,3\n\n\n2,6",
        "3,4\n\n\n2,6",
        "3,5\n\n\n2,6",
        "3,6\n\n\n2,6",
        "3,7\n\n\n2,6",
        "3,8\n\n\n2,6",
        "3,9\n\n\n2,6",
        "3,10\n\n\n2,6",
        "3,11\n\n\n2,6",
        "3,12\n\n\n2,6",
        {"c": "#aaaaaa", "w": 2},
        "3,13\n\n\n2,6"
      ],
      [
        {"x": 2.5},
        "3,0\n\n\n2,7",
        "3,1\n\n\n2,7",
        {"c": "#cccccc"},
        "3,2\n\n\n2,7",
        "3,3\n\n\n2,7",
        "3,4\n\n\n2,7",
        "3,5\n\n\n2,7",
        "3,6\n\n\n2,7",
        "3,7\n\n\n2,7",
        "3,8\n\n\n2,7",
        "3,9\n\n\n2,7",
        "3,10\n\n\n2,7",
        "3,11\n\n\n2,7",
        "3,12\n\n\n2,7",
        {"c": "#aaaaaa", "w": 2},
        "3,13\n\n\n2,7"
      ],
      [
        {"x": 2.5, "w": 2},
        "3,0\n\n\n2,8",
        {"c": "#cccccc"},
        "3,2\n\n\n2,8",
        "3,3\n\n\n2,8",
        "3,4\n\n\n2,8",
        "3,5\n\n\n2,8",
        "3,6\n\n\n2,8",
        "3,7\n\n\n2,8",
        "3,8\n\n\n2,8",
        "3,9\n\n\n2,8",
        "3,10\n\n\n2,8",
        "3,11\n\n\n2,8",
        "3,12\n\n\n2,8",
        {"c": "#aaaaaa"},
        "3,13\n\n\n2,8",
        "3,14\n\n\n2,8"
      ],
      [
        {"x": 2.5},
        "3,0\n\n\n2,9",
        "3,1\n\n\n2,9",
        {"c": "#cccccc"},
        "3,2\n\n\n2,9",
        "3,3\n\n\n2,9",
        "3,4\n\n\n2,9",
        "3,5\n\n\n2,9",
        "3,6\n\n\n2,9",
        "3,7\n\n\n2,9",
        "3,8\n\n\n2,9",
        "3,9\n\n\n2,9",
        "3,10\n\n\n2,9",
        "3,11\n\n\n2,9",
        "3,12\n\n\n2,9",
        {"c": "#aaaaaa"},
        "3,13\n\n\n2,9",
        "3,14\n\n\n2,9"
      ],
      [
        {"y": 0.5, "x": 2.5, "w": 1.5},
        "4,0\n\n\n3,1",
        "4,1\n\n\n3,1",
        {"w": 1.5},
        "4,3\n\n\n3,1",
        {"c": "#cccccc", "w": 7},
        "4,6\n\n\n3,1",
        {"c": "#aaaaaa", "w": 1.5},
        "4,11\n\n\n3,1",
        "4,13\n\n\n3,1",
        {"w": 1.5},
        "4,14\n\n\n3,1"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "4,0\n\n\n3,2",
        {"w": 1.25},
        "4,1\n\n\n3,2",
        {"w": 1.25},
        "4,3\n\n\n3,2",
        {"c": "#cccccc", "w": 6.25},
        "4,6\n\n\n3,2",
        {"c": "#aaaaaa"},
        "4,10\n\n\n3,2",
        "4,11\n\n\n3,2",
        "4,12\n\n\n3,2",
        "4,13\n\n\n3,2",
        "4,14\n\n\n3,2"
      ],
      [
        {"x": 2.5, "w": 1.5},
        "4,0\n\n\n3,3",
        "4,1\n\n\n3,3",
        {"w": 1.25},
        "4,3\n\n\n3,3",
        {"c": "#cccccc", "w": 6.25},
        "4,6\n\n\n3,3",
        {"c": "#aaaaaa"},
        "4,10\n\n\n3,3",
        "4,11\n\n\n3,3",
        "4,12\n\n\n3,3",
        "4,13\n\n\n3,3",
        "4,14\n\n\n3,3"
      ],
      [
        {"x": 2.5, "w": 1.5},
        "4,0\n\n\n3,4",
        {"w": 1.5},
        "4,1\n\n\n3,4",
        {"c": "#cccccc", "w": 7},
        "4,6\n\n\n3,4",
        {"c": "#aaaaaa"},
        "4,10\n\n\n3,4",
        "4,11\n\n\n3,4",
        "4,12\n\n\n3,4",
        "4,13\n\n\n3,4",
        "4,14\n\n\n3,4"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "4,0\n\n\n3,5",
        {"w": 1.25},
        "4,1\n\n\n3,5",
        {"w": 1.25},
        "4,3\n\n\n3,5",
        {"c": "#cccccc", "w": 2.25},
        "4,4\n\n\n3,5",
        {"w": 1.25},
        "4,6\n\n\n3,5",
        {"w": 2.75},
        "4,8\n\n\n3,5",
        {"c": "#aaaaaa", "w": 1.25},
        "4,10\n\n\n3,5",
        {"w": 1.25},
        "4,11\n\n\n3,5",
        {"w": 1.25},
        "4,13\n\n\n3,5",
        {"w": 1.25},
        "4,14\n\n\n3,5"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "4,0\n\n\n3,6",
        {"w": 1.25},
        "4,1\n\n\n3,6",
        {"w": 1.25},
        "4,3\n\n\n3,6",
        {"c": "#cccccc", "w": 2.25},
        "4,4\n\n\n3,6",
        {"w": 1.25},
        "4,6\n\n\n3,6",
        {"w": 2.75},
        "4,8\n\n\n3,6",
        {"c": "#aaaaaa"},
        "4,10\n\n\n3,6",
        "4,11\n\n\n3,6",
        "4,12\n\n\n3,6",
        "4,13\n\n\n3,6",
        "4,14\n\n\n3,6"
      ],
      [
        {"rx": 0.25, "y": 6.5, "x": 13.5, "w": 1.75},
        "3,12\n\n\n2,2",
        "3,13\n\n\n2,2",
        "3,14\n\n\n2,2"
      ],
      [
        {"y": 2, "x": 13.5, "w": 1.75},
        "3,12\n\n\n2,5",
        "3,13\n\n\n2,5",
        "3,14\n\n\n2,5"
      ]
    ]
  }
}