[
    {
        "name": "Cornelius"
    },
    [
        {
            "r": 10,
            "y": 0.7,
            "x": 4.15,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "3\n\nF3\n\n\n\n#\n\n\nE"
    ],
    [
        {
            "y": -0.87,
            "x": 5.15
        },
        "4\n\nF4\n\n\n\n$\n\n\nR"
    ],
    [
        {
            "y": -0.8800000000000001,
            "x": 3.15
        },
        "2\n\nF2\n\n\n\n@\n\n\nW",
        {
            "x": 2
        },
        "5\n\nF5\n\n\n\n%\n\n\nT"
    ],
    [
        {
            "y": -0.6199999999999999,
            "x": 1.15
        },
        "Esc\n\nF12\n\n\n\n\n\n\nTab",
        "1\n\nF1\n\n\n\n!\n\n\nQ"
    ],
    [
        {
            "y": -0.6300000000000001,
            "x": 4.15,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "D"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 5.15
        },
        "F"
    ],
    [
        {
            "y": -0.8800000000000001,
            "x": 3.15
        },
        "S",
        {
            "x": 2
        },
        "G"
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 1.15,
            "c": "#cdcdcd"
        },
        "Ctrl",
        {
            "c": "#cccccc"
        },
        "A"
    ],
    [
        {
            "y": -0.6299999999999999,
            "x": 4.15
        },
        "C"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 5.15
        },
        "V"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 3.15
        },
        "X",
        {
            "x": 2
        },
        "B"
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 1.15,
            "c": "#cdcdcd"
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Z"
    ],
    [
        {
            "y": -0.3799999999999999,
            "x": 3.15,
            "c": "#cdcdcd"
        },
        "",
        {
            "x": 0.5
        },
        "Alt"
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 1.15
        },
        "",
        ""
    ],
    [
        {
            "r": 25,
            "y": -3.0300000000000002,
            "x": 6.7,
            "c": "#8c94ff"
        },
        "Lower"
    ],
    [
        {
            "r": 40,
            "y": -3.07,
            "x": 8.3,
            "c": "#cdcdcd"
        },
        "Space"
    ],
    [
        {
            "r": -40,
            "y": 8.18,
            "x": 1.65
        },
        "Enter"
    ],
    [
        {
            "r": -25,
            "y": -2.0600000000000005,
            "x": 5.25,
            "c": "#ff7575"
        },
        "Raise"
    ],
    [
        {
            "r": -10,
            "y": -6.1499999999999995,
            "x": 8.95,
            "c": "#cccccc",
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "8\n\nF8\n\n\n\n*\n\n\nI"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 7.95
        },
        "7\n\nF7\n\n\n\n&\n\n\nU"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 6.95
        },
        "6\n\nF6\n\n\n\n^\n\n\nY",
        {
            "x": 1.9999999999999991
        },
        "9\n\nF9\n\n\n\n(\n\n\nO"
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 10.95
        },
        "0\n\nF10\n\n\n\n)\n\n\nP",
        "Del\n\nF11\n\n\n\n\n\n\nBsp"
    ],
    [
        {
            "y": -0.6299999999999999,
            "x": 8.95,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111"
        },
        "Home\n\n=\n\n\n\n\n+\n\nK"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 7.95
        },
        "\n\n-\n\n\n\n\n_\n\nJ"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 6.95,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "H",
        {
            "x": 1.9999999999999991,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Up\n\n\\\n\n\n\n\n|\n\nL"
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 10.95,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 1]
        },
        "End\n\n`\n\n\n\n\n~\n\n;\n:",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n\"\n\n\n\n\n'"
    ],
    [
        {
            "y": -0.6299999999999999,
            "x": 8.95,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "Left\n\n]\n\n\n\n\n}\n\n,\n<"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 7.95,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111"
        },
        "\n\n[\n\n\n\n\n{\n\nM"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 6.95,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "N",
        {
            "x": 1.9999999999999991,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Down\n\n\n\n\n\n\n\n\n.\n>"
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 10.95
        },
        "Right\n\n\n\n\n\n\n\n\n/\n?",
        {
            "c": "#8c94ff",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Lower"
    ],
    [
        {
            "y": -0.3799999999999999,
            "x": 8.45,
            "c": "#cdcdcd"
        },
        "Super",
        {
            "x": 0.5
        },
        ""
    ],
    [
        {
            "y": -0.6200000000000001,
            "x": 10.95
        },
        "",
        ""
    ]
]
