[
    [
        "Esc",
        {
            "x": 1
        },
        "F1",
        "F2",
        "F3",
        "F4",
        {
            "x": 0.5
        },
        "F5",
        "F6",
        "F7",
        "F8",
        {
            "x": 0.5
        },
        "F9",
        "F10",
        "F11",
        "F12",
        {
            "x": 0.25
        },
        "Print",
        "Scroll",
        "Pause"
    ],
    [
        {
            "y": 0.25
        },
        "~\n`",
        "!\n1",
        "@\n2",
        "#\n3",
        "$\n4",
        "%\n5",
        "^\n6",
        "&\n7",
        "*\n8",
        "(\n9",
        ")\n0",
        "_\n-",
        "+\n=",
        {
            "w": 2
        },
        "Backspace",
        {
            "x": 0.25
        },
        "Insert",
        "Home",
        "PgUp",
        {
            "x": 0.25
        },
        "Num Lock",
        "/",
        "*",
        "-"
    ],
    [
        {
            "w": 1.5
        },
        "Tab",
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        "{\n[",
        "}\n]",
        {
            "w": 1.5
        },
        "|\n\\",
        {
            "x": 0.25
        },
        "Delete",
        "End",
        "PgDn",
        {
            "x": 0.25
        },
        "7\nHome",
        "8\n↑",
        "9\nPgUp",
        {
            "h": 2
        },
        "+"
    ],
    [
        {
            "w": 1.75
        },
        "Caps",
        "A",
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G",
        "H",
        {
            "n": true
        },
        "J",
        "K",
        "L",
        ":\n;",
        "\"\n'",
        {
            "w": 2.25
        },
        "Enter",
        {
            "x": 3.5
        },
        "4\n←",
        "5",
        "6\n→"
    ],
    [
        {
            "w": 2.25
        },
        "Shift",
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        "<\n,",
        ">\n.",
        "?\n/",
        {
            "w": 2.75
        },
        "Shift",
        {
            "x": 1.25
        },
        "↑",
        {
            "x": 1.25
        },
        "1\nEnd",
        "2\n↓",
        "3\nPgDn",
        {
            "h": 2
        },
        "Enter"
    ],
    [
        {
            "w": 1.25
        },
        "Control",
        {
            "w": 1.25
        },
        "Win",
        {
            "w": 1.25
        },
        "Alt",
        {
            "a": 7,
            "w": 6.25
        },
        "",
        {
            "a": 4,
            "w": 1.25
        },
        "Alt",
        {
            "w": 1.25
        },
        "Super",
        {
            "w": 1.25
        },
        "Fn",
        {
            "w": 1.25
        },
        "Control",
        {
            "x": 0.25
        },
        "←",
        "↓",
        "→",
        {
            "x": 0.25,
            "w": 2
        },
        "0\nIns",
        ".\nDel"
    ],
    [
        {
            "y": 0.5
        },
        "Esc",
        "~\n`",
        {
            "x": 12
        },
        "|\n\\",
        {
            "x": 0.25
        },
        "Delete",
        "End",
        "PgDn"
    ],
    [
        {
            "x": 12,
            "w": 1.5
        },
        "|\n\\",
        {
            "w": 1.5
        },
        "Bksp",
        {
            "x": 0.25
        },
        "PgUp",
        {
            "x": 2.5,
            "w": 1.25,
            "h": 2,
            "w2": 1.5,
            "h2": 1,
            "x2": -0.25
        },
        "Enter",
        {
            "x": 0.25,
            "w": 1.25,
            "h": 2,
            "w2": 1.5,
            "h2": 1,
            "x2": -0.25
        },
        "Enter",
        "-"
    ],
    [
        {
            "w": 1.25,
            "w2": 1.75,
            "l": true
        },
        "Caps",
        {
            "x": 0.5,
            "w": 1.75
        },
        "Control",
        {
            "x": 1.25,
            "n": true
        },
        "F",
        {
            "x": 2,
            "n": true
        },
        "J",
        {
            "x": 4,
            "w": 2.25
        },
        "Enter",
        {
            "x": 0.25
        },
        "PgDn",
        {
            "x": 1.5
        },
        "|\n\\",
        {
            "x": 2.75
        },
        "+"
    ],
    [
        {
            "x": 2,
            "a": 7,
            "w": 2
        },
        "",
        {
            "w": 2.25
        },
        "",
        {
            "a": 4
        },
        "B",
        {
            "a": 7,
            "w": 2.75
        },
        "",
        {
            "x": 3.25,
            "a": 4,
            "w": 1.75
        },
        "Shift",
        {
            "x": 0.25
        },
        "End",
        "Fn",
        {
            "x": 0.5,
            "w": 1.25
        },
        "Shift",
        "<\n>",
        {
            "x": 1.5,
            "h": 2
        },
        "Enter"
    ],
    [
        {
            "w": 1.5
        },
        "Control",
        "Super",
        {
            "w": 1.5
        },
        "Alt",
        {
            "a": 7,
            "w": 7
        },
        "",
        {
            "a": 4,
            "w": 1.5
        },
        "Alt",
        "Super",
        {
            "w": 1.5
        },
        "Control",
        {
            "x": 0.25
        },
        "Ctrl",
        "Alt",
        {
            "x": 3.25
        },
        "0"
    ]
]
