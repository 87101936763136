{
  "name": "Epoch 80",
  "vendorId": "0x6463",
  "productId": "0x0000",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes"],
  "menus": ["via/keymap", "via/layouts", "via/macros", "via/save_load"],
  "matrix": {"rows": 6, "cols": 17},
  "layouts": {
    "labels": [
      "Split BackSpace",
      "Split LShift",
      "Split RShift",
      ["Bottom Row", "6.25u Standard", "7u WKL"],
      "ISO Enter"
    ],
    "keymap": [
      [
        {"x": 2.5, "c": "#777777"},
        "0,0",
        {"x": 1, "c": "#cccccc"},
        "0,2",
        "0,3",
        "0,4",
        "0,5",
        {"x": 0.5, "c": "#aaaaaa"},
        "0,6",
        "0,7",
        "0,8",
        "0,9",
        {"x": 0.5, "c": "#cccccc"},
        "0,10",
        "0,11",
        "0,12",
        "0,13",
        {"x": 0.5, "c": "#aaaaaa"},
        "0,14",
        "0,15",
        "0,16"
      ],
      [
        {"y": 0.5, "x": 2.5, "c": "#cccccc"},
        "1,0",
        "1,1",
        "1,2",
        "1,3",
        "1,4",
        "1,5",
        "1,6",
        "1,7",
        "1,8",
        "1,9",
        "1,10",
        "1,11",
        "1,12",
        {"c": "#aaaaaa", "w": 2},
        "1,13\n\n\n0,0",
        {"x": 0.5},
        "1,14",
        "1,15",
        "1,16",
        {"x": 1, "c": "#cccccc"},
        "1,13\n\n\n0,1",
        "3,14\n\n\n0,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.5},
        "2,0",
        {"c": "#cccccc"},
        "2,1",
        "2,2",
        "2,3",
        "2,4",
        "2,5",
        "2,6",
        "2,7",
        "2,8",
        "2,9",
        "2,10",
        "2,11",
        "2,12",
        {"w": 1.5},
        "2,13\n\n\n4,0",
        {"x": 0.5, "c": "#aaaaaa"},
        "2,14",
        "2,15",
        "2,16",
        {
          "x": 1.75,
          "c": "#777777",
          "w": 1.25,
          "h": 2,
          "w2": 1,
          "h2": 1,
          "x2": -0.25
        },
        "3,13\n\n\n4,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.75},
        "3,0",
        {"c": "#cccccc"},
        "3,1",
        "3,2",
        "3,3",
        "3,4",
        "3,5",
        "3,6",
        "3,7",
        "3,8",
        "3,9",
        "3,10",
        "3,11",
        {"c": "#777777", "w": 2.25},
        "3,13\n\n\n4,0",
        {"x": 4.25, "c": "#cccccc"},
        "2,13\n\n\n4,1"
      ],
      [
        {"c": "#aaaaaa", "w": 1.25},
        "4,0\n\n\n1,1",
        {"c": "#cccccc"},
        "4,1\n\n\n1,1",
        {"x": 0.25, "c": "#aaaaaa", "w": 2.25},
        "4,0\n\n\n1,0",
        {"c": "#cccccc"},
        "4,2",
        "4,3",
        "4,4",
        "4,5",
        "4,6",
        "4,7",
        "4,8",
        "4,9",
        "4,10",
        "4,11",
        {"c": "#aaaaaa", "w": 2.75},
        "4,12\n\n\n2,0",
        {"x": 1.5},
        "4,15",
        {"x": 1.25, "w": 1.75},
        "4,12\n\n\n2,1",
        "4,13\n\n\n2,1"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "5,0\n\n\n3,0",
        {"w": 1.25},
        "5,1\n\n\n3,0",
        {"w": 1.25},
        "5,2\n\n\n3,0",
        {"c": "#cccccc", "w": 6.25},
        "5,6\n\n\n3,0",
        {"c": "#aaaaaa", "w": 1.25},
        "5,10\n\n\n3,0",
        {"w": 1.25},
        "5,11\n\n\n3,0",
        {"w": 1.25},
        "5,12\n\n\n3,0",
        {"w": 1.25},
        "5,13\n\n\n3,0",
        {"x": 0.5},
        "5,14",
        "5,15",
        "5,16"
      ],
      [
        {"y": 0.25, "x": 2.5, "w": 1.5},
        "5,0\n\n\n3,1",
        "5,1\n\n\n3,1",
        {"w": 1.5},
        "5,2\n\n\n3,1",
        {"c": "#cccccc", "w": 7},
        "5,6\n\n\n3,1",
        {"c": "#aaaaaa", "w": 1.5},
        "5,11\n\n\n3,1",
        "5,12\n\n\n3,1",
        {"w": 1.5},
        "5,13\n\n\n3,1"
      ]
    ]
  }
}