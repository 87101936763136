{
  "name": "TKC1800",
  "vendorId": "0x544B",
  "productId": "0x0001",
  "lighting": "qmk_backlight_rgblight",
  "matrix": {"rows": 7, "cols": 19},
  "layouts": {
    "labels": [
      "Split Backspace",
      "ISO Enter",
      "Split Left Shift",
      ["Spacebar", "7U", "6.25U"],
      ["Right Mods", "1.5U/1.5U", "1U/1U/1U"],
      "2U Numpad Plus",
      "Split Numpad Enter"
    ],
    "keymap": [
      [
        {
          "x": 2.5,
          "c": "#aaaaaa"
        },
        "0,0",
        {
          "x": 1,
          "c": "#cccccc"
        },
        "0,2",
        "0,3",
        "0,4",
        "0,5",
        {
          "x": 0.5,
          "c": "#aaaaaa"
        },
        "0,6",
        "0,7",
        "0,8",
        "0,9",
        {
          "x": 0.5,
          "c": "#cccccc"
        },
        "0,10",
        "0,11",
        "0,12",
        "0,13",
        {
          "x": 0.5,
          "c": "#aaaaaa"
        },
        "0,15",
        "0,16",
        "0,17",
        "0,18"
      ],
      [
        {
          "x": 18
        },
        "1,15",
        "1,16",
        "1,17",
        "1,18"
      ],
      [
        {
          "x": 2.5,
          "c": "#cccccc"
        },
        "2,0",
        "2,1",
        "2,2",
        "2,3",
        "2,4",
        "2,5",
        "2,6",
        "2,7",
        "2,8",
        "2,9",
        "2,10",
        "2,11",
        "2,12",
        {
          "c": "#aaaaaa",
          "w": 2
        },
        "2,13\n\n\n0,0",
        {
          "x": 0.5
        },
        "2,15",
        "2,16",
        "2,17",
        "2,18",
        {
          "x": 0.25,
          "c": "#cccccc"
        },
        "2,13\n\n\n0,1",
        "2,14\n\n\n0,1"
      ],
      [
        {
          "x": 2.5,
          "c": "#aaaaaa",
          "w": 1.5
        },
        "3,0",
        {
          "c": "#cccccc"
        },
        "3,1",
        "3,2",
        "3,3",
        "3,4",
        "3,5",
        "3,6",
        "3,7",
        "3,8",
        "3,9",
        "3,10",
        "3,11",
        "3,12",
        {
          "w": 1.5
        },
        "3,13\n\n\n1,0",
        {
          "x": 0.5
        },
        "3,15",
        "3,16",
        "3,17",
        {
          "c": "#aaaaaa"
        },
        "3,18\n\n\n5,0",
        {
          "x": 1.25,
          "w": 1.25,
          "h": 2,
          "w2": 1.5,
          "h2": 1,
          "x2": -0.25
        },
        "4,13\n\n\n1,1",
        {
          "x": 0.25,
          "h": 2
        },
        "4,18\n\n\n5,1"
      ],
      [
        {
          "x": 2.5,
          "w": 1.75
        },
        "4,0",
        {
          "c": "#cccccc"
        },
        "4,1",
        "4,2",
        "4,3",
        "4,4",
        "4,5",
        "4,6",
        "4,7",
        "4,8",
        "4,9",
        "4,10",
        "4,11",
        {
          "c": "#aaaaaa",
          "w": 2.25
        },
        "4,13\n\n\n1,0",
        {
          "x": 0.5,
          "c": "#cccccc"
        },
        "4,15",
        "4,16",
        "4,17",
        {
          "c": "#aaaaaa"
        },
        "4,18\n\n\n5,0",
        {
          "x": 0.25,
          "c": "#cccccc"
        },
        "4,12\n\n\n1,1"
      ],
      [
        {
          "c": "#aaaaaa",
          "w": 1.25
        },
        "5,0\n\n\n2,1",
        {
          "c": "#cccccc"
        },
        "5,1\n\n\n2,1",
        {
          "x": 0.25,
          "c": "#aaaaaa",
          "w": 2.25
        },
        "5,0\n\n\n2,0",
        {
          "c": "#cccccc"
        },
        "5,2",
        "5,3",
        "5,4",
        "5,5",
        "5,6",
        "5,7",
        "5,8",
        "5,9",
        "5,10",
        "5,11",
        {
          "c": "#aaaaaa",
          "w": 1.75
        },
        "5,12",
        {
          "x": 1.5,
          "c": "#cccccc"
        },
        "5,15",
        "5,16",
        "5,17",
        {
          "c": "#aaaaaa",
          "h": 2
        },
        "6,18\n\n\n6,0",
        {
          "x": 2.75
        },
        "5,18\n\n\n6,1"
      ],
      [
        {
          "y": -0.75,
          "x": 16.75
        },
        "5,13"
      ],
      [
        {
          "y": -0.25,
          "x": 2.5,
          "w": 1.5
        },
        "6,0\n\n\n3,0",
        {
          "w": 1.5
        },
        "6,1\n\n\n3,0",
        {
          "c": "#cccccc",
          "w": 7
        },
        "6,5\n\n\n3,0",
        {
          "c": "#aaaaaa",
          "w": 1.5
        },
        "6,10\n\n\n4,0",
        {
          "w": 1.5
        },
        "6,11\n\n\n4,0",
        {
          "x": 3.5,
          "c": "#cccccc"
        },
        "6,16",
        "6,17",
        {
          "x": 3.75,
          "c": "#aaaaaa"
        },
        "6,18\n\n\n6,1"
      ],
      [
        {
          "y": -0.75,
          "x": 15.75
        },
        "6,12",
        "6,13",
        "6,14"
      ],
      [
        {
          "x": 2.5,
          "w": 1.25
        },
        "6,0\n\n\n3,1",
        {
          "w": 1.25
        },
        "6,1\n\n\n3,1",
        {
          "w": 1.25
        },
        "6,2\n\n\n3,1",
        {
          "c": "#cccccc",
          "w": 6.25
        },
        "6,5\n\n\n3,1",
        {
          "c": "#aaaaaa"
        },
        "6,9\n\n\n4,1",
        "6,10\n\n\n4,1",
        "6,11\n\n\n4,1"
      ]
    ]
  }
}
