{
  "name": "Polaris",
  "vendorId": "0xa103",
  "productId": "0x0002",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes", "via/qmk_lighting"],
  "menus": [
    "via/keymap",
    "via/layouts",
    "via/macros",
    "via/save_load",
    "core/qmk_backlight",
    "core/qmk_rgblight"
  ],
  "matrix": {"rows": 5, "cols": 14},
  "layouts": {
    "labels": [
      ["Backspace", "Unified", "Split"],
      ["Enter", "ANSI", "ISO"],
      ["Left Shift", "ANSI", "ISO"],
      ["Right Shift", "Split", "Unified"],
      [
        "Bottom Row",
        "Tsangan",
        "WKL",
        "HHKB",
        "1.25U ANSI/Unified Spacebar",
        "1.25U ANSI/Split Spacebar"
      ]
    ],
    "keymap": [
      [
        {"x": 2.5, "c": "#777777"},
        "0,0",
        {"c": "#cccccc"},
        "0,1",
        "0,2",
        "0,3",
        "0,4",
        "0,5",
        "0,6",
        "0,7",
        "0,8",
        "0,9",
        "0,10",
        "0,11",
        "0,12",
        {"c": "#aaaaaa", "w": 2},
        "0,13\n\n\n0,0",
        {"x": 1, "c": "#cccccc"},
        "0,13\n\n\n0,1",
        "2,12\n\n\n0,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.5},
        "1,0",
        {"c": "#cccccc"},
        "1,1",
        "1,2",
        "1,3",
        "1,4",
        "1,5",
        "1,6",
        "1,7",
        "1,8",
        "1,9",
        "1,10",
        "1,11",
        "1,12",
        {"c": "#aaaaaa", "w": 1.5},
        "1,13\n\n\n1,0",
        {
          "x": 1.75,
          "c": "#777777",
          "w": 1.25,
          "h": 2,
          "w2": 1.5,
          "h2": 1,
          "x2": -0.25
        },
        "2,13\n\n\n1,1"
      ],
      [
        {"x": 2.5, "c": "#aaaaaa", "w": 1.75},
        "2,0",
        {"c": "#cccccc"},
        "2,1",
        "2,2",
        "2,3",
        "2,4",
        "2,5",
        "2,6",
        "2,7",
        "2,8",
        "2,9",
        "2,10",
        "2,11",
        {"c": "#777777", "w": 2.25},
        "2,13\n\n\n1,0",
        {"x": 0.75, "c": "#cccccc"},
        "1,13\n\n\n1,1"
      ],
      [
        {"c": "#aaaaaa", "w": 1.25},
        "3,0\n\n\n2,1",
        "3,1\n\n\n2,1",
        {"x": 0.25, "w": 2.25},
        "3,0\n\n\n2,0",
        {"c": "#cccccc"},
        "3,2",
        "3,3",
        "3,4",
        "3,5",
        "3,6",
        "3,7",
        "3,8",
        "3,9",
        "3,10",
        "3,11",
        {"c": "#aaaaaa", "w": 1.75},
        "3,12\n\n\n3,0",
        "3,13\n\n\n3,0",
        {"x": 0.25, "w": 2.75},
        "3,13\n\n\n3,1"
      ],
      [
        {"x": 2.5, "w": 1.5},
        "4,0\n\n\n4,0",
        "4,1\n\n\n4,0",
        {"w": 1.5},
        "4,2\n\n\n4,0",
        {"c": "#cccccc", "w": 7},
        "4,6\n\n\n4,0",
        {"c": "#aaaaaa", "w": 1.5},
        "4,11\n\n\n4,0",
        "4,12\n\n\n4,0",
        {"w": 1.5},
        "4,13\n\n\n4,0"
      ],
      [
        {"y": 0.25, "x": 2.5, "w": 1.5},
        "4,0\n\n\n4,1",
        {"d": true},
        "\n\n\n4,1",
        {"w": 1.5},
        "4,2\n\n\n4,1",
        {"c": "#cccccc", "w": 7},
        "4,6\n\n\n4,1",
        {"c": "#aaaaaa", "w": 1.5},
        "4,11\n\n\n4,1",
        {"d": true},
        "\n\n\n4,1",
        {"w": 1.5},
        "4,13\n\n\n4,1"
      ],
      [
        {"x": 2.5, "w": 1.5, "d": true},
        "\n\n\n4,2",
        "4,1\n\n\n4,2",
        {"w": 1.5},
        "4,2\n\n\n4,2",
        {"c": "#cccccc", "w": 7},
        "4,6\n\n\n4,2",
        {"c": "#aaaaaa", "w": 1.5},
        "4,11\n\n\n4,2",
        "4,12\n\n\n4,2",
        {"w": 1.5, "d": true},
        "\n\n\n4,2"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "4,0\n\n\n4,3",
        {"w": 1.25},
        "4,1\n\n\n4,3",
        {"w": 1.25},
        "4,2\n\n\n4,3",
        {"c": "#cccccc", "w": 6.25},
        "4,6\n\n\n4,3",
        {"c": "#aaaaaa", "w": 1.25},
        "4,10\n\n\n4,3",
        {"w": 1.25},
        "4,11\n\n\n4,3",
        {"w": 1.25},
        "4,12\n\n\n4,3",
        {"w": 1.25},
        "4,13\n\n\n4,3"
      ],
      [
        {"x": 2.5, "w": 1.25},
        "4,0\n\n\n4,4",
        {"w": 1.25},
        "4,1\n\n\n4,4",
        {"w": 1.25},
        "4,2\n\n\n4,4",
        {"c": "#cccccc", "w": 2.25},
        "4,4\n\n\n4,4",
        {"w": 1.25},
        "4,6\n\n\n4,4",
        {"w": 2.75},
        "4,8\n\n\n4,4",
        {"c": "#aaaaaa", "w": 1.25},
        "4,10\n\n\n4,4",
        {"w": 1.25},
        "4,11\n\n\n4,4",
        {"w": 1.25},
        "4,12\n\n\n4,4",
        {"w": 1.25},
        "4,13\n\n\n4,4"
      ]
    ]
  }
}