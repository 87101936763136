[
    {
        "name": "Miryoku",
        "author": "Manna Harbour",
        "notes": "https://github.com/manna-harbour/miryoku/"
    },
    [
        {
            "y": -0.5,
            "x": 2,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "F\nF8\n*\n8",
        {
            "x": 6,
            "t": "#000000\n\n#888800\n#008888",
            "fa": [0, 0, 2, 2]
        },
        "U\n\nCopy\nCopy"
    ],
    [
        {
            "y": -0.87,
            "x": 1,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "W\nF7\n&\n7",
        {
            "x": 1,
            "f": 3
        },
        "P\nF9\n(\n9",
        {
            "x": 4,
            "t": "#000000\n\n#888800\n#008888"
        },
        "L\n\nPaste\nPaste",
        {
            "x": 1
        },
        "Y\n\nCut\nCut"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 4,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f2": 2
        },
        "B\nPrtScn SysRq\n}\n]",
        {
            "x": 2,
            "t": "#000000\n\n#888800\n#008888"
        },
        "J\n\nRedo\nRedo"
    ],
    [
        {
            "y": -0.75,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "Q\nF12\n{\n[",
        {
            "x": 10,
            "t": "#000000\n\n#888800\n#008888"
        },
        "'\n\nUndo\nUndo"
    ],
    [
        {
            "y": -0.5,
            "x": 2,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "S\nF5\n%\n5\nCtrl",
        {
            "x": 6,
            "t": "#000000\n#880088\n#888800\n#008888",
            "f": 3
        },
        "E\n🔉\n⬇\n▼\nCtrl"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 1,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "R\nF4\n$\n4\nAlt",
        {
            "x": 1,
            "f": 3,
            "n": true
        },
        "T\nF6\n^\n6\nShift",
        {
            "x": 4,
            "t": "#000000\n#880088\n#888800\n#008888",
            "f": 3,
            "n": true
        },
        "N\n⏮\n⬅\n◀\nShift",
        {
            "x": 1,
            "f": 3
        },
        "I\n🔊\n⬆\n▲\nAlt"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 4,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "G\nScroll Lock\n+\n=",
        {
            "x": 2,
            "t": "#000000\n\n#888800\n#008888",
            "f": 3
        },
        "M\n\n\nCaps Lock"
    ],
    [
        {
            "y": -0.75,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "A\nF11\n:\n;\nSuper",
        {
            "x": 10,
            "t": "#000000\n#880088\n#888800\n#008888",
            "f": 3
        },
        "O\n⏭\n➡\n▶\nSuper"
    ],
    [
        {
            "y": -0.5,
            "x": 2,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "C\nF2\n@\n2",
        {
            "x": 6,
            "t": "#000000\n\n#888800\n#008888",
            "f": 3
        },
        ",\n\n⇩\nPage Down"
    ],
    [
        {
            "y": -0.8700000000000001,
            "x": 1,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "X\nF1\n!\n1\nAltGr",
        {
            "x": 1,
            "f": 3
        },
        "D\nF3\n#\n3",
        {
            "x": 4,
            "t": "#000000\n\n#888800\n#008888",
            "f": 3
        },
        "H\n\n⇦\nHome",
        {
            "x": 1,
            "f": 3
        },
        ".\n\n⇧\nPage Up\nAltGr"
    ],
    [
        {
            "y": -0.8799999999999999,
            "x": 4,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "V\nPause Break\n|\n\\",
        {
            "x": 2,
            "t": "#000000\n\n\n#008888",
            "f": 3
        },
        "K\n\n\nInsert"
    ],
    [
        {
            "y": -0.75,
            "t": "#000000\n#880000\n#008800\n#000088",
            "f": 3
        },
        "Z\nF10\n~\n`",
        {
            "x": 10,
            "t": "#000000\n\n#888800\n#008888",
            "f": 3
        },
        "/\n\n⇨\nEnd"
    ],
    [
        {
            "y": -0.33999999999999986,
            "x": 2.5,
            "t": "#000000\n#880000\n#008800\n#000088\n#880088",
            "f": 3
        },
        "Esc\n≣\n(\n.\nMedia",
        {
            "x": 5,
            "t": "#000000\n#880088\n#888800\n#008888\n#880000",
            "f": 3
        },
        "⌦\n🔇\n2\n⌦\nFun"
    ],
    [
        {
            "r": 18,
            "y": -2.25,
            "x": 4.5,
            "t": "#000000\n#880000\n#008800\n#000088\n#008888",
            "a": 4,
            "n": true
        },
        "Spc\nSpc\n)\n0\nNav"
    ],
    [
        {
            "r": 36,
            "y": -2.8,
            "x": 6,
            "t": "#000000\n#880000\n#008800\n#000088\n#888800"
        },
        "⭾\n⭾\n_\n-\nMouse"
    ],
    [
        {
            "r": -36,
            "y": 6.07,
            "x": 2.73,
            "t": "#000000\n#880088\n#888800\n#008888\n#008800"
        },
        "↵\n◼\n1\n↵\nSym"
    ],
    [
        {
            "r": -18,
            "y": -2.54,
            "x": 5.93,
            "t": "#000000\n#880088\n#888800\n#008888\n#000088",
            "n": true
        },
        "⌫\n⏯\n3\n⌫\nNum"
    ]
]
