{
    "ref": "layout",
    "fixedKeys": [
        {
            "ref": "0.41782254895491544",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [3.65, 0.85],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.9656968120270644",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [13.1, 0.85],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.2650950989273628",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [0.5, 0.9],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.036981432519344715",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [1.65, 0.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.13866477172533997",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [2.65, 0.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.5494198383217388",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [14.1, 0.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.44279312222510914",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [0.3, 1.9],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.8332435733572394",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [12.8, 1.9],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.7356245630729086",
            "blank": {
                "boxes": [{"height": 1, "width": 1.5, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.75, 0.5]
            },
            "position": [1.45, 1.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.5220711141461161",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [2.95, 1.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.7240142229410664",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [13.8, 1.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.6532485018357883",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [14.8, 1.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.09476037118754421",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [0.1, 2.9],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.9561680340208347",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [3, 2.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.49147793109283233",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [13.25, 2.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.6591653980599836",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [14.25, 2.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.009891707948402662",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [3.3, 3.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.4063687345312941",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [12.95, 3.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.907280863634135",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [13.95, 3.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.39613543691876063",
            "blank": {
                "boxes": [{"height": 1, "width": 1.75, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.875, 0.5]
            },
            "position": [14.95, 3.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.1373831843982536",
            "blank": {
                "boxes": [{"height": 1, "width": 1.5, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.75, 0.5]
            },
            "position": [1.05, 4.95],
            "angle": 0,
            "orientation": 270
        },
        {
            "ref": "0.4637181029168682",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [5.9, 0.14999999999999947],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.7534331681690036",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [6.9, 0.15000000000000002],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.9604592638610707",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [7.9, 0.15000000000000002],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.932683450992233",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [4.9, 0.15000000000000036],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.38600462393833657",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [4.35, 1.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.7030722200464166",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [5.35, 1.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.03740153868958984",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [6.35, 1.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.6792557349186183",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [7.35, 1.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.148592294417359",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [4.55, 2.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.3066562141335776",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [5.55, 2.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.7149613944577693",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [6.55, 2.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.7482526628513164",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [7.55, 2.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.772520399319836",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [5.05, 3.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.49019623137999724",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [6.05, 3.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.6332693224026817",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [7.05, 3.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.5599196330712957",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [8.05, 3.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.9017144561261254",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [8.55, 4.15],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.5372096446270971",
            "blank": {
                "boxes": [{"height": 1, "width": 1.5, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.75, 0.5]
            },
            "position": [4.8, 4.2],
            "angle": 10,
            "orientation": 270
        },
        {
            "ref": "0.2991575169252325",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [8.6, 3.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.7102422047192363",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [9.6, 3.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.9609180748106936",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [10.6, 3.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.9345608572831845",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [11.6, 3.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.31696454244456995",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [8.1, 4.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.19713372570723053",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [9.1, 4.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.1581372372608223",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [10.1, 4.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.3326042067082262",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [11.1, 4.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.19780645794483553",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [7.85, 6.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.45322385936736254",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [8.85, 6.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.9833928007259292",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [9.85, 6.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.3831701963351115",
            "blank": {
                "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.5, 0.5]
            },
            "position": [10.85, 6.15],
            "angle": -10,
            "orientation": 270
        },
        {
            "ref": "0.7090746387293527",
            "blank": {
                "boxes": [{"height": 1, "width": 1.5, "offset": [0, 0]}],
                "stabilizers": [],
                "stem": [0.75, 0.5]
            },
            "position": [10.6, 7.2],
            "angle": -10,
            "orientation": 270
        }
    ],
    "fixedBlockers": [
        {
            "ref": "0.4382691818635369",
            "boxes": [{"height": 1, "width": 2.25, "offset": [0, 0]}],
            "position": [6.3, 4.15],
            "angle": 10
        }
    ],
    "variableSections": [
        {
            "ref": "left shift",
            "options": [
                {
                    "ref": "ansi",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.43349254278083094",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 2.25,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 0,
                                        "length": 1.25,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [1.125, 0.5]
                            },
                            "position": [1.05, 3.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "iso",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.43349254278083094-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1.25,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.625, 0.5]
                            },
                            "position": [1.05, 3.95],
                            "angle": 0,
                            "orientation": 90
                        },
                        {
                            "ref": "0.43349254278083094-2",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [2.3, 3.95],
                            "angle": 0,
                            "orientation": 90
                        }
                    ]
                }
            ]
        },
        {
            "ref": "stepped caps TODO",
            "options": [
                {
                    "ref": "normal",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.23940420239527116",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1.75,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.875, 0.5]
                            },
                            "position": [1.25, 2.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "stepped",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.23940420239527116-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1.75,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.625, 0.5]
                            },
                            "shelf": [
                                {
                                    "height": 1,
                                    "width": 1.25,
                                    "offset": [0, 0]
                                }
                            ],
                            "position": [1.25, 2.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                }
            ]
        },
        {
            "ref": "backspace section",
            "options": [
                {
                    "ref": "2u backspace",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.5496543810863703",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 2,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 0,
                                        "length": 1,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [1, 0.5]
                            },
                            "position": [15.1, 0.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "split backspace",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.5496543810863703-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [15.1, 0.95],
                            "angle": 0,
                            "orientation": 270
                        },
                        {
                            "ref": "0.5496543810863703-2",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [16.1, 0.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                }
            ]
        },
        {
            "ref": "top right solo",
            "options": [
                {
                    "ref": "key",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.06445813950396229",
                            "blank": {
                                "boxes": [
                                    {"height": 1, "width": 1, "offset": [0, 0]}
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [17.7, 0.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "encoder",
                    "blockers": [
                        {
                            "ref": "0.06445813950396229-2",
                            "label": "encoder",
                            "boxes": [
                                {"height": 1, "width": 1, "offset": [0, 0]}
                            ],
                            "position": [17.7, 0.95],
                            "angle": 0
                        }
                    ],
                    "keys": []
                }
            ]
        },
        {
            "ref": "split space section",
            "options": [
                {
                    "ref": "full space",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.9705071904967628",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 2.75,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 0,
                                        "length": 1.75,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [1.375, 0.5]
                            },
                            "position": [7.85, 7.15],
                            "angle": -10,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "split space",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.9705071904967628-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [7.85, 7.15],
                            "angle": -10,
                            "orientation": 270
                        },
                        {
                            "ref": "0.9705071904967628-2",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1.75,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.875, 0.5]
                            },
                            "position": [8.85, 7.15],
                            "angle": -10,
                            "orientation": 270
                        }
                    ]
                }
            ]
        },
        {
            "ref": "big arrow",
            "options": [
                {
                    "ref": "vertical",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.21420400616735935-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 2,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 90,
                                        "length": 1,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [0.5, 1]
                            },
                            "position": [16.7, 3.95],
                            "angle": 0,
                            "orientation": 270
                        },
                        {
                            "ref": "0.21420400616735935-2",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [15.7, 4.95],
                            "angle": 0,
                            "orientation": 270
                        },
                        {
                            "ref": "0.21420400616735935-3",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [17.7, 4.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "chonk",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.21420400616735935-4",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 3,
                                        "offset": [0, 0]
                                    },
                                    {
                                        "height": 2,
                                        "width": 1,
                                        "offset": [1, -1]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 90,
                                        "length": 1,
                                        "offset": [1.5, -0.5]
                                    }
                                ],
                                "stem": [1.5, 0]
                            },
                            "position": [15.7, 4.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                }
            ]
        },
        {
            "ref": "test homing",
            "options": [
                {
                    "ref": "standard",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.9589218535220114",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [8.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        },
                        {
                            "ref": "0.2945775888935336",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [9.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        },
                        {
                            "ref": "0.9189168611427863",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [10.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        },
                        {
                            "ref": "0.04875012309274629",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [11.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "big middle",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.9589218535220114asd",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [8.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        },
                        {
                            "ref": "0.2945775888935336asd",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 2,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [1, 0.5]
                            },
                            "position": [9.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        },
                        {
                            "ref": "0.04875012309274629ads",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [11.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "double",
                    "blockers": [
                        {
                            "ref": "0.9189168611427863-2",
                            "label": "screen",
                            "boxes": [
                                {
                                    "height": 1,
                                    "width": 2,
                                    "offset": [0, 0]
                                }
                            ],
                            "position": [10.35, 5.15],
                            "angle": -10
                        }
                    ],
                    "keys": [
                        {
                            "ref": "0.9589218535220114-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 2,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 0,
                                        "length": 1,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [1, 0.5]
                            },
                            "position": [8.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "single",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.9589218535220114-3",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 4,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 0,
                                        "length": 3,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [2, 0.5]
                            },
                            "position": [8.35, 5.15],
                            "angle": -10,
                            "orientation": 270
                        }
                    ]
                }
            ]
        },
        {
            "ref": "enter",
            "options": [
                {
                    "ref": "ansi",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.1440995011945414",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1.7,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.875, 0.5]
                            },
                            "position": [15.8, 1.95],
                            "angle": 0,
                            "orientation": 270
                        },
                        {
                            "ref": "0.7710057571796907",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 2.25,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 0,
                                        "length": 1.25,
                                        "offset": [0.5, 0.5]
                                    }
                                ],
                                "stem": [1.125, 0.5]
                            },
                            "position": [15.25, 2.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                },
                {
                    "ref": "iso",
                    "blockers": [],
                    "keys": [
                        {
                            "ref": "0.1440995011945414-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1.7,
                                        "offset": [0, 0]
                                    },
                                    {
                                        "height": 2,
                                        "width": 1.25,
                                        "offset": [0.45, 0]
                                    }
                                ],
                                "stabilizers": [
                                    {
                                        "angle": 90,
                                        "length": 1,
                                        "offset": [1.05, 0.5]
                                    }
                                ],
                                "stem": [1.05, 1]
                            },
                            "shelf": [
                                {
                                    "height": 2,
                                    "width": 1.25,
                                    "offset": [0.45, 0]
                                }
                            ],
                            "position": [15.8, 1.95],
                            "angle": 0,
                            "orientation": 270
                        },
                        {
                            "ref": "0.7710057571796907-1",
                            "blank": {
                                "boxes": [
                                    {
                                        "height": 1,
                                        "width": 1,
                                        "offset": [0, 0]
                                    }
                                ],
                                "stabilizers": [],
                                "stem": [0.5, 0.5]
                            },
                            "position": [15.25, 2.95],
                            "angle": 0,
                            "orientation": 270
                        }
                    ]
                }
            ]
        }
    ]
}
