[
    {
        "name": "microdox",
        "plate": true
    },
    [
        {
            "y": 0.2,
            "x": 2,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "3\n\nF3\n\n\n\n#\n\n\nE",
        {
            "x": 5.5
        },
        "8\n\nF8\n\n\n\n*\n\n\nI"
    ],
    [
        {
            "y": -0.85,
            "x": 1
        },
        "2\n\nF2\n\n\n\n@\n\n\nW",
        {
            "x": 1
        },
        "4\n\nF4\n\n\n\n$\n\n\nR",
        {
            "x": 3.5
        },
        "7\n\nF7\n\n\n\n&\n\n\nU",
        {
            "x": 1
        },
        "9\n\nF9\n\n\n\n(\n\n\nO"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 4
        },
        "5\n\nF5\n\n\n\n%\n\n\nT",
        {
            "x": 1.5
        },
        "6\n\nF6\n\n\n\n^\n\n\nY"
    ],
    [
        {
            "y": -0.9
        },
        "1\n\nF1\n\n\n\n!\n\n\nQ",
        {
            "x": 9.5
        },
        "0\n\nF10\n\n\n\n)\n\n\nP"
    ],
    [
        {
            "y": -0.40000000000000013,
            "x": 2,
            "t": "#111111\n\n\n\n#000000",
            "a": 7,
            "fa": [3]
        },
        "D\n\n\n\nCtrl",
        {
            "x": 5.5,
            "t": "#0100ff\n\n#ff0000\n\n#000000\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\n=\n\nCtrl\n\n\n+\n\nK"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 1,
            "t": "#111111\n\n\n\n#000000",
            "a": 7
        },
        "S\n\n\n\nAlt",
        {
            "x": 1,
            "n": true
        },
        "F\n\n\n\nShift",
        {
            "x": 3.5,
            "t": "#0100ff\n\n#ff0000\n\n#000000\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "n": true
        },
        "\n\n-\n\nShift\n\n\n_\n\nJ",
        {
            "x": 1
        },
        "\n\n\\\n\nAlt\n\n\n|\n\nL"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 4,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111"
        },
        "\n\nF11\n\n\n\n\n\n\nG",
        {
            "x": 1.5,
            "t": "#111111\n\n#ff0000"
        },
        "\n\nF12\n\n\n\n\n\n\nH"
    ],
    [
        {
            "y": -0.8999999999999999,
            "t": "#111111\n\n\n\n#000000",
            "a": 7
        },
        "A\n\n\n\nSuper",
        {
            "x": 9.5,
            "t": "#0100ff\n\n#ff0000\n\n#000000\n\n\n#ff0000\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "'\n\n`\n\nSuper\n\n\"\n~\n\n;\n:"
    ],
    [
        {
            "y": -0.3999999999999999,
            "x": 2,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "C",
        {
            "x": 5.5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Down\n\n]\n\n\n\n\n}\n\n,\n<"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 1,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "X",
        {
            "x": 1
        },
        "V",
        {
            "x": 3.5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Right\n\n[\n\n\n\n\n{\n\nM",
        {
            "x": 1,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Up\n\n\n\n\n\n\n\n\n.\n>"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 4,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "B",
        {
            "x": 1.5
        },
        "N\n\n\n\nLower"
    ],
    [
        {
            "y": -0.8999999999999999
        },
        "Z",
        {
            "x": 9.5,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Right\n\n\n\n\n\n\n\n\n/\n?"
    ],
    [
        {
            "y": 0.10000000000000009,
            "x": 2,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "Esc\n\n\n\n\n\n\n\n\nTab",
        {
            "x": 5.5,
            "t": "#111111\n\n#ff0000"
        },
        "\n\nDel\n\n\n\n\n\n\nBsp"
    ],
    [
        {
            "r": 15,
            "y": -1.9000000000000004,
            "x": 4.15,
            "c": "#8c94ff",
            "t": "#111111",
            "a": 7,
            "fa": [3],
            "n": true
        },
        "Lower"
    ],
    [
        {
            "r": 30,
            "y": -2.8,
            "x": 5.9,
            "c": "#cdcdcd"
        },
        "Space"
    ],
    [
        {
            "r": -30,
            "y": 4.8,
            "x": 3.05
        },
        "Enter"
    ],
    [
        {
            "r": -15,
            "y": -2.05,
            "x": 5.95,
            "c": "#ff7575",
            "n": true
        },
        "Raise"
    ]
]
