[
    {
        "name": "Bear65"
    },
    [
        {
            "y": 0.85,
            "x": 3.65,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F2\n\n\n\n\n\n\n\n\n2\n@",
        {
            "x": 8.45
        },
        "F11\n\n\n\n\n\n\n\n\n-\n_"
    ],
    [
        {
            "y": -0.9500000000000001,
            "x": 0.5,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Esc"
    ],
    [
        {
            "y": -0.95,
            "x": 1.65,
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n~\n\n\n\n\n`",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "F1\n\n\n\n\n\n\n\n\n1\n!",
        {
            "x": 10.45
        },
        "F12\n\n\n\n\n\n\n\n\n=\n+",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3],
            "w": 2
        },
        "Backspace",
        {
            "x": 0.5999999999999979
        },
        "Delete"
    ],
    [
        {
            "y": -0.050000000000000044,
            "x": 0.3
        },
        "Home",
        {
            "x": 11.5
        },
        "P"
    ],
    [
        {
            "y": -0.95,
            "x": 1.45,
            "t": "#0100ff\n\n\n#111111\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 1.5
        },
        "Caps\n\n\n\n\n\n\n\n\nTab",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Q",
        {
            "x": 9.850000000000001,
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n{\n\n\n\n\n[",
        {
            "t": "#111111"
        },
        "\n}\n\n\n\n\n]",
        {
            "w": 1.5
        },
        "\n|\n\n\n\n\n\\"
    ],
    [
        {
            "y": -0.050000000000000266,
            "x": 0.1,
            "a": 7
        },
        "End"
    ],
    [
        {
            "y": -0.9499999999999997,
            "x": 1.25,
            "c": "#cdcdcd",
            "w": 1.75
        },
        "Ctrl",
        {
            "c": "#cccccc"
        },
        "A",
        {
            "x": 9.25,
            "a": 5
        },
        "\n:\n\n\n\n\n;",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "fa": [3, 3]
        },
        "\n\"\n\n\n\n\n'",
        {
            "t": "#111111",
            "a": 7,
            "w": 2.25
        },
        "Enter"
    ],
    [
        {
            "x": 1.05,
            "c": "#cdcdcd",
            "w": 2.25
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Z",
        {
            "x": 8.649999999999999,
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n>\n\n\n\n\n.",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111"
        },
        "\n?\n\n\n\n\n/",
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "w": 1.75
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Up"
    ],
    [
        {
            "x": 1.05,
            "c": "#cdcdcd",
            "w": 1.5
        },
        "Super",
        {
            "x": 13.149999999999999,
            "c": "#cccccc"
        },
        "Left",
        "Down",
        "Right"
    ],
    [
        {
            "r": 10,
            "y": -5.8,
            "x": 4.9,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "F3\n\n\n\n\n\n\n\n\n3\n#"
    ],
    [
        {
            "y": -1.0000000000000004,
            "x": 5.9
        },
        "F4\n\n\n\n\n\n\n\n\n4\n$"
    ],
    [
        {
            "y": -0.9999999999999999,
            "x": 6.9
        },
        "F5\n\n\n\n\n\n\n\n\n5\n%"
    ],
    [
        {
            "y": -0.9999999999999999,
            "x": 7.9
        },
        "F6\n\n\n\n\n\n\n\n\n6\n^"
    ],
    [
        {
            "x": 4.35,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "W",
        "E",
        "R",
        "T"
    ],
    [
        {
            "x": 4.55
        },
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G"
    ],
    [
        {
            "x": 5.05
        },
        "X",
        "C",
        "V",
        "B"
    ],
    [
        {
            "x": 6.3,
            "c": "#cdcdcd",
            "w": 2.25
        },
        "Space",
        {
            "c": "#8c94ff"
        },
        "Fn"
    ],
    [
        {
            "y": -0.9500000000000002,
            "x": 4.8,
            "c": "#cdcdcd",
            "w": 1.5
        },
        "Alt"
    ],
    [
        {
            "r": -10,
            "y": -2.0500000000000003,
            "x": 8.6,
            "c": "#cccccc",
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F7\n\n\n\n\n\n\n\n\n7\n&",
        "F8\n\n\n\n\n\n\n\n\n8\n*",
        "F9\n\n\n\n\n\n\n\n\n9\n(",
        "F10\n\n\n\n\n\n\n\n\n0\n)"
    ],
    [
        {
            "x": 8.1,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Y",
        "U",
        "I",
        "O"
    ],
    [
        {
            "x": 8.35
        },
        "H",
        {
            "n": true
        },
        "J",
        "K",
        "L"
    ],
    [
        {
            "x": 7.85
        },
        "B",
        "N",
        "M",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111",
            "a": 5,
            "fa": [3, 0, 0, 0, 0, 0, 3]
        },
        "\n<\n\n\n\n\n,"
    ],
    [
        {
            "x": 7.85,
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "w": 2.75
        },
        "Enter"
    ],
    [
        {
            "y": -0.9500000000000002,
            "x": 10.6,
            "w": 1.5
        },
        "Alt"
    ]
]
