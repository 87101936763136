[
    {
        "name": "TKC1800"
    },
    [
        {
            "a": 7
        },
        "Esc",
        {
            "x": 1
        },
        "F1",
        "F2",
        "F3",
        "F4",
        {
            "x": 0.5
        },
        "F5",
        "F6",
        "F7",
        "F8",
        {
            "x": 0.5
        },
        "F9",
        "F10",
        "F11",
        "F12",
        {
            "x": 0.5
        },
        "Insert",
        "Home",
        "PgUp",
        "Scroll Lock"
    ],
    [
        {
            "x": 15.5
        },
        "Delete",
        "End",
        "PgDn",
        "Pause Break"
    ],
    [
        {
            "a": 5,
            "fa": [0, 2, 0, 0, 0, 0, 0]
        },
        "\n~\n\n\n\n\n`",
        "\n!\n\n\n\n\n1",
        "\n@\n\n\n\n\n2",
        "\n#\n\n\n\n\n3",
        "\n$\n\n\n\n\n4",
        "\n%\n\n\n\n\n5",
        "\n^\n\n\n\n\n6",
        "\n&\n\n\n\n\n7",
        "\n*\n\n\n\n\n8",
        "\n(\n\n\n\n\n9",
        "\n)\n\n\n\n\n0",
        "\n_\n\n\n\n\n-",
        "\n+\n\n\n\n\n=",
        {
            "a": 7,
            "w": 2
        },
        "Backspace",
        {
            "x": 0.5
        },
        "Num Lock",
        "/",
        "*",
        "PrtSc"
    ],
    [
        {
            "w": 1.5
        },
        "Tab",
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        {
            "a": 5
        },
        "\n[\n\n\n\n\n{",
        "\n]\n\n\n\n\n}",
        {
            "w": 1.5
        },
        "\n\\\n\n\n\n\n|",
        {
            "x": 0.5
        },
        "\nHome\n\n\n\n\n7",
        "\n↑\n\n\n\n\n8",
        "\nPgUp\n\n\n\n\n9",
        {
            "a": 7
        },
        "-"
    ],
    [
        {
            "w": 1.25,
            "w2": 1.75,
            "l": true
        },
        "Ctrl",
        {
            "x": 0.5
        },
        "A",
        "S",
        "D",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        {
            "a": 5
        },
        "\n;\n\n\n\n\n:",
        "\n'\n\n\n\n\n\"",
        {
            "a": 7,
            "w": 2.25
        },
        "Enter",
        {
            "x": 0.5,
            "a": 5
        },
        "\n←\n\n\n\n\n4",
        {
            "a": 7
        },
        "5",
        {
            "a": 5
        },
        "\n→\n\n\n\n\n6",
        {
            "a": 7
        },
        "+"
    ],
    [
        {
            "w": 2.25
        },
        "Shift",
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        {
            "a": 5
        },
        "\n,\n\n\n\n\n<",
        "\n.\n\n\n\n\n>",
        "\n/\n\n\n\n\n?",
        {
            "a": 7,
            "w": 1.75
        },
        "Shift",
        {
            "x": 1.5,
            "a": 5
        },
        "\nEnd\n\n\n\n\n1",
        "\n↓\n\n\n\n\n2",
        "\nPgDn\n\n\n\n\n3",
        {
            "a": 7,
            "h": 2
        },
        "Enter"
    ],
    [
        {
            "y": -0.75,
            "x": 14.25
        },
        "↑"
    ],
    [
        {
            "y": -0.25,
            "w": 1.5
        },
        "",
        {
            "w": 1.5
        },
        "Alt",
        {
            "w": 7
        },
        "",
        {
            "w": 1.5
        },
        "Alt",
        {
            "w": 1.5
        },
        "Super",
        {
            "x": 3.5,
            "a": 5
        },
        "\nIns\n\n\n\n\n0",
        "\nDel\n\n\n\n\n."
    ],
    [
        {
            "y": -0.75,
            "x": 13.25,
            "a": 7
        },
        "←",
        "↓",
        "→"
    ]
]
