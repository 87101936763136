{
  "name": "Candybar lefty",
  "vendorId": "0x544B",
  "productId": "0x0003",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes"],
  "menus": ["via/keymap", "via/layouts", "via/macros", "via/save_load"],
  "matrix": {"rows": 4, "cols": 17},
  "layouts": {
    "labels": ["2u Backspace", "Flipped right shift", "6.25u spacebar", "2u 0"],
    "keymap": [
      [
        "0,13",
        "0,14",
        "0,15",
        "0,16",
        {"c": "#777777"},
        "0,0",
        {"c": "#cccccc"},
        "0,1",
        "0,2",
        "0,3",
        "0,4",
        "0,5",
        "0,6",
        "0,7",
        "0,8",
        "0,9",
        "0,10",
        "0,11\n\n\n0,0",
        "0,12\n\n\n0,0",
        {"x": 0.25, "c": "#aaaaaa", "w": 2},
        "0,12\n\n\n0,1"
      ],
      [
        {"c": "#cccccc"},
        "1,13",
        "1,14",
        "1,15",
        "1,16",
        {"c": "#aaaaaa", "w": 1.25},
        "1,0",
        {"c": "#cccccc"},
        "1,1",
        "1,2",
        "1,3",
        "1,4",
        "1,5",
        "1,6",
        "1,7",
        "1,8",
        "1,9",
        "1,10",
        {"c": "#777777", "w": 1.75},
        "1,12"
      ],
      [
        {"c": "#cccccc"},
        "2,13",
        "2,14",
        "2,15",
        "2,16",
        {"c": "#aaaaaa", "w": 1.75},
        "2,0",
        {"c": "#cccccc"},
        "2,2",
        "2,3",
        "2,4",
        "2,5",
        "2,6",
        "2,7",
        "2,8",
        "2,9",
        "2,10",
        {"c": "#aaaaaa", "w": 1.25},
        "2,11\n\n\n1,0",
        {"c": "#777777"},
        "2,12\n\n\n1,0",
        {"x": 0.25},
        "2,11\n\n\n1,1",
        {"c": "#aaaaaa", "w": 1.25},
        "2,12\n\n\n1,1"
      ],
      [
        {"c": "#cccccc"},
        "3,13\n\n\n3,0",
        "3,14\n\n\n3,0",
        "3,15",
        "3,16",
        {"c": "#aaaaaa", "w": 1.25},
        "3,0",
        {"w": 1.25},
        "3,1",
        {"w": 1.25},
        "3,2",
        {"w": 2.75},
        "3,5\n\n\n2,0",
        {"w": 2.25},
        "3,8\n\n\n2,0",
        {"w": 1.25},
        "3,9\n\n\n2,0",
        {"c": "#cccccc"},
        "3,10",
        {"c": "#777777"},
        "3,11",
        "3,12"
      ],
      [
        {"y": 0.25, "c": "#cccccc", "w": 2},
        "3,14\n\n\n3,1",
        {"x": 1.75, "c": "#aaaaaa", "w": 6.25},
        "3,7\n\n\n2,1"
      ]
    ]
  }
}