[
    {
        "name": "creator",
        "plate": true
    },
    [
        {
            "c": "#edd568",
            "f": 2
        },
        "\nF1\n\nF1\n\n\n\n\n\nF1",
        "\nF2\n\nF2\n\n\n\n\n\nF2",
        "\nF3\n\nF3\n\n\n\n\n\nF3",
        {
            "c": "#cccccc",
            "a": 7,
            "f": 3
        },
        "F4",
        {
            "n": true
        },
        "F5",
        {
            "n": true
        },
        "F6",
        {
            "n": true
        },
        "F7",
        {
            "n": true
        },
        "F8",
        "F9",
        "F10",
        "F11",
        "F12"
    ],
    [
        {
            "y": 0.5,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Esc\n\nF12\n\n\n\n\n\n\nTab",
        "1\n\nF1\n\n\n\n!\n\n\nQ",
        "2\n\nF2\n\n\n\n@\n\n\nW",
        "3\n\nF3\n\n\n\n#\n\n\nE",
        "4\n\nF4\n\n\n\n$\n\n\nR",
        "5\n\nF5\n\n\n\n%\n\n\nT",
        "6\n\nF6\n\n\n\n^\n\n\nY",
        "7\n\nF7\n\n\n\n&\n\n\nU",
        "8\n\nF8\n\n\n\n*\n\n\nI",
        "9\n\nF9\n\n\n\n(\n\n\nO",
        "0\n\nF10\n\n\n\n)\n\n\nP",
        "Del\n\nF11\n\n\n\n\n\n\nBsp",
        {
            "x": 0.25,
            "c": "#edd568",
            "t": "#000000",
            "f": 2
        },
        "\nVol-\n\nVol+\n\n\n\n\n\nPlay",
        {
            "x": 0.5
        },
        "\n\n\nRedo\n\n\nUndo"
    ],
    [
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Ctrl",
        {
            "c": "#cccccc"
        },
        "A",
        "S",
        "D",
        {
            "n": true
        },
        "F",
        "G",
        "H",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "n": true
        },
        "\n\n-\n\n\n\n\n_\n\nJ",
        {
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Home\n\n=\n\n\n\n\n+\n\nK",
        "Up\n\n\\\n\n\n\n\n|\n\nL",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111"
        },
        "End\n\n`\n\n\n\n\n~\n\n;\n:",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n\"\n\n\n\n\n'"
    ],
    [
        {
            "y": -0.5,
            "x": 13.75,
            "t": "#000000",
            "a": 7,
            "f": 3,
            "h2": 2
        },
        "Mute"
    ],
    [
        {
            "y": -0.5,
            "c": "#cdcdcd",
            "t": "#111111",
            "f": 2,
            "fa": [3]
        },
        "Shift",
        {
            "c": "#cccccc"
        },
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\n[\n\n\n\n\n{\n\nM",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Left\n\n]\n\n\n\n\n}\n\n,\n<",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Down\n\n\n\n\n\n\n\n\n.\n>",
        "Right\n\n\n\n\n\n\n\n\n/\n?",
        {
            "c": "#8c94ff",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Lower"
    ],
    [
        {
            "c": "#cccccc",
            "t": "#000000",
            "f": 3
        },
        "",
        "",
        "",
        {
            "c": "#cdcdcd",
            "t": "#111111",
            "f": 2,
            "fa": [3]
        },
        "Alt",
        {
            "c": "#8c94ff",
            "n": true
        },
        "Lower",
        {
            "c": "#cdcdcd"
        },
        "Space",
        "Enter",
        {
            "c": "#ff7575",
            "n": true
        },
        "Raise",
        {
            "c": "#cdcdcd"
        },
        "Super",
        {
            "c": "#cccccc",
            "t": "#000000",
            "f": 3
        },
        "",
        "",
        ""
    ]
]
