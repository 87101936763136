{
  "name": "Bear 65 Ergo",
  "vendorId": "0xA13B",
  "productId": "0x000A",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes", "via/qmk_lighting"],
  "menus": [
    "via/keymap",
    "via/layouts",
    "via/macros",
    "via/save_load",
    "core/qmk_backlight",
    "core/qmk_rgblight"
  ],
  "matrix": {"rows": 5, "cols": 15},
  "layouts": {
    "labels": ["Split Backspace"],
    "keymap": [
      [{"x": 15.25}, "0,13\n\n\n0,1", "0,14\n\n\n0,1"],
      [
        {"y": 0.15, "x": 0.55, "c": "#aaaaaa"},
        "3,1",
        {"x": 2.15, "c": "#cccccc"},
        "0,2",
        {"x": 8.55},
        "0,11"
      ],
      [
        {"y": -0.9, "x": 1.7, "c": "#777777"},
        "0,0",
        {"c": "#cccccc"},
        "0,1",
        {"x": 10.55},
        "0,12",
        {"c": "#aaaaaa", "w": 2},
        "0,13\n\n\n0,0",
        {"x": 0.6},
        "4,14"
      ],
      [{"y": -0.1, "x": 0.35}, "1,14"],
      [{"y": -1, "x": 12.95, "c": "#cccccc"}, "1,10"],
      [
        {"y": -0.95, "x": 1.5, "c": "#aaaaaa", "w": 1.5},
        "1,0",
        {"c": "#cccccc"},
        "1,1",
        {"x": 9.95},
        "1,11",
        "1,12",
        {"c": "#aaaaaa", "w": 1.5},
        "1,13"
      ],
      [{"y": -0.1, "x": 0.15}, "2,14"],
      [
        {"y": -0.9, "x": 1.3, "w": 1.75},
        "2,0",
        {"c": "#cccccc"},
        "2,1",
        {"x": 9.35},
        "2,10",
        {"x": 0},
        "2,11",
        {"c": "#aaaaaa", "w": 2.25},
        "2,13"
      ],
      [
        {"x": 1.1, "w": 2.25},
        "3,0",
        {"c": "#cccccc"},
        "3,2",
        {"x": 8.75},
        "3,11",
        "3,12",
        {"c": "#aaaaaa", "w": 1.75},
        "3,13",
        {"c": "#777777"},
        "3,14"
      ],
      [
        {"x": 1.1, "c": "#aaaaaa", "w": 1.5},
        "4,0",
        {"x": 13.25, "c": "#777777"},
        "4,11",
        "4,12",
        "4,13"
      ],
      [{"r": 12, "y": -6, "x": 5, "c": "#cccccc"}, "0,3", "0,4", "0,5", "0,6"],
      [{"x": 4.5}, "1,2", "1,3", "1,4", "1,5"],
      [{"x": 4.8}, "2,2", "2,3", "2,4", "2,5"],
      [{"x": 5.3}, "3,3", "3,4", "3,5", "3,6"],
      [{"x": 6.45, "w": 2.25}, "4,5", {"c": "#aaaaaa"}, "4,6"],
      [{"y": -0.95, "x": 4.95, "w": 1.5}, "4,3"],
      [
        {"r": -12, "y": -1.45, "x": 8.55, "c": "#cccccc"},
        "0,7",
        "0,8",
        "0,9",
        "0,10"
      ],
      [{"x": 8.05}, "1,6", "1,7", "1,8", "1,9"],
      [{"x": 8.2}, "2,6", "2,7", "2,8", "2,9"],
      [{"x": 7.75}, "3,7", "3,8", "3,9", "3,10"],
      [{"x": 7.75, "w": 2.75}, "4,8"],
      [{"y": -0.95, "x": 10.5, "c": "#aaaaaa", "w": 1.5}, "4,10"]
    ]
  }
}