"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.mightErr = exports.isErrOfType = exports.isErr = exports.newErr = void 0;
// Create a new `Err` with the given message.
var newErr = function (message) {
    return new Err(null, message, null);
};
exports.newErr = newErr;
// Type guard to check if a `Possible` value is an `Err`.
var isErr = function (value) {
    return value instanceof Err;
};
exports.isErr = isErr;
// Type guard to check if a `Possible` value is an `Err` that also matches the
// matcher type. All the ancestor types of the `value` will be compared to only
// the most recent identity of the `matcher`.
var isErrOfType = function (value, matcher) {
    if (!(0, exports.isErr)(value))
        return false;
    var pValue = value;
    var pMatcher = matcher;
    return !!pValue
        .nextErrs()
        .find(function (e) { return e.$possible_identity === pMatcher.$possible_identity; });
};
exports.isErrOfType = isErrOfType;
function mightErr(expr) {
    var _this = this;
    // Expression is a callback.
    if (typeof expr === "function") {
        try {
            return expr();
        }
        catch (e) {
            return (0, exports.newErr)(String(e));
        }
    }
    // Expression is a promise.
    if (typeof expr["catch"] === "function" && typeof expr.then === "function") {
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, expr
                            .then(function (v) { return resolve(v); })["catch"](function (e) { return resolve((0, exports.newErr)(String(e))); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    }
    return expr;
}
exports.mightErr = mightErr;
// To disallow confusing calls to `isErr` guarded Possible values.
//     <allowed> possibleValue.err.print();
// <not allowed> possibleValue.print();
// <not allowed> possibleValue.err.err;
var UnresolvedErr = /** @class */ (function () {
    function UnresolvedErr() {
    }
    return UnresolvedErr;
}());
// TODO 2022-06-07 Add stack and other info to err.
var Err = /** @class */ (function () {
    function Err($possible_identity, message, nextErr) {
        this.err = this;
        this.nextErr = null;
        this.$possible_identity = $possible_identity || Math.random();
        this.message = message;
        this.nextErr = nextErr;
    }
    // Utility to traverse `Err` ancestry and convert it into an array.
    // In order of most distant ancestor first.
    Err.prototype.nextErrs = function () {
        var cur = this;
        var errs = [];
        while (cur.nextErr !== null) {
            errs.push(cur);
            cur = cur.nextErr;
        }
        errs.push(cur);
        return errs;
    };
    // Wrap the `Err` instance to add more context. When printed, the described
    // message will be formatted as: `<described message>: <original message>`.
    Err.prototype.describe = function (messageOrErr) {
        if (typeof messageOrErr === "string") {
            return new Err(null, messageOrErr, this);
        }
        return new Err(messageOrErr.$possible_identity, messageOrErr.message, this);
    };
    // Print the error with all the described messages and `Err`s in order.
    // <example> newErr("a").describe("b").describe("c").print()
    //  <output> c: b: a
    Err.prototype.print = function () {
        return this.nextErrs()
            .map(function (e) { return e.message; })
            .join(": ");
    };
    return Err;
}());
