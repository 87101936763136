{
    "id": {
        "vendorID": "0.15426003902501884",
        "productID": "0.42878324616727337"
    },
    "name": "botanical r2",
    "kits": [
        {
            "id": {
                "vendorID": "0.5583475544132777",
                "productID": "0.8013325202631791"
            },
            "name": "base",
            "keys": [
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.24463679921190185", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Esc",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3359295944002527", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F1",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.804205256862927", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F2",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4168993172474684", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F3",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4992323930281277", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F4",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [5, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9464157704426215", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "F5",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [6.5, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9778692088149825", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "F6",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [7.5, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.19376161393250424", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "F7",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [8.5, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.820177209666733", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "F8",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [9.5, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.25622494610976077", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F9",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7219385098149544", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F10",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6888935906393601", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F11",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2605345594816002", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "F12",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [14, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8557884888682366", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Print",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.05606014653887592", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Scroll",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3194040677210297", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Pause",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.25, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7135759485850326", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [{"color": "GN20", "text": "~"}],
                            [{"color": "GN20", "text": "`"}]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9441523658371509", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "!",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "1",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7788476350210465", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "@",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "2",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4114766565760759", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "#",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "3",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2844691159673931", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "$",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "4",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.033952374574183875", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "%",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "5",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [5, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9985785185146683", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "^",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "6",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [6, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.15528712460095462", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "&",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "7",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [7, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8543654693456579", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "*",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "8",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [8, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5141856068921968", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "(",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "9",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [9, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2275654540127232", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": ")",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "0",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [10, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.379684906837237", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [{"color": "GN20", "text": "_"}],
                            [{"color": "GN20", "text": "-"}]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4782524263069985", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [{"color": "GN20", "text": "+"}],
                            [{"color": "GN20", "text": "="}]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 2, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.697408278393731", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Backspace",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "GN20",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8795627550225269", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Insert",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.42421367728674797", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Home",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.507688408844579", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgUp",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.25, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.48017902277194824", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Num",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [18.5, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5153596964450764", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [{"color": "GN21", "text": "÷"}],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [19.5, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6910283909617185", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [{"color": "GN21", "text": "×"}],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [20.5, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6058854647325702", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [{"color": "GN21", "text": "−"}],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [21.5, 1.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.04354669193257865", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Tab",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9671311861221721", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Q"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6809611706467982", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "W"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.05916945211906488", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "E"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.38816461067531227", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "R"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9495851543675697", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "T"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8162497583311117", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Y"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3399009056994555", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "U"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.22400590715950108", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "I"}]],
                        "keycodeAffinity": []
                    },
                    "position": [8.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8152424986042333", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "O"}]],
                        "keycodeAffinity": []
                    },
                    "position": [9.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.537998840361908", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "P"}]],
                        "keycodeAffinity": []
                    },
                    "position": [10.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8868316086276906", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "{",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "[",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.24160079257233114", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "}",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "]",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.11139628400804624", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "|",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "\\",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.300645998524099", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Delete",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3447797336557512", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "End",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4108135093106744", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgDn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.25, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.08288969300375038", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "7"}]],
                        "keycodeAffinity": []
                    },
                    "position": [18.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8831612722052096", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "8"}]],
                        "keycodeAffinity": []
                    },
                    "position": [19.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9485163595303365", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "9"}]],
                        "keycodeAffinity": []
                    },
                    "position": [20.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 2, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [0.5, 1]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3797152572990399", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [{"color": "GN21", "text": "+"}],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [21.5, 2.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1204549731675808", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Caps",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.373850241463048", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "A"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5637422582145486", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "S"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7746883675942096", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "D"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5744167291782178", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "F"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7119542225837621", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "G"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4054588389161342", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "H"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5455308380351707", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "J"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.0518364762804131", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "K"}]],
                        "keycodeAffinity": []
                    },
                    "position": [8.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.11397845847975807", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "L"}]],
                        "keycodeAffinity": []
                    },
                    "position": [9.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8253133013770739", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": ":",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": ";",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [10.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5759801106051088", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "\"",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "'",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.33969486819622685", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12.75, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.03073772897800886", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "4"}]],
                        "keycodeAffinity": []
                    },
                    "position": [18.5, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.990465959838434", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "5"}]],
                        "keycodeAffinity": []
                    },
                    "position": [19.5, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.35415478095977826", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "6"}]],
                        "keycodeAffinity": []
                    },
                    "position": [20.5, 3.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5068520488704551", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8742910645542747", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Z"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5014704309596052", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "X"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1542642679053523", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "C"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4740606389275075", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "V"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4652607632936996", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "B"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5117216768111048", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "N"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.04694783320856688", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "M"}]],
                        "keycodeAffinity": []
                    },
                    "position": [8.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7148933671559454", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "<",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": ",",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [9.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6862346030153077", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": ">",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": ".",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [10.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.08360362694897261", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "?",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "/",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.75,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.375, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5992039608230262", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6902146622083762", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN21", "text": "Up"}]],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3220930675163489", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "1"}]],
                        "keycodeAffinity": []
                    },
                    "position": [18.5, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.07266139255948256", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "2"}]],
                        "keycodeAffinity": []
                    },
                    "position": [19.5, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7336959816711937", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "3"}]],
                        "keycodeAffinity": []
                    },
                    "position": [20.5, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 2, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 90,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [0.5, 1]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9663511953113906", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [21.5, 4.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8047265422994496", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Control",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.010075835489354867", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Super",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1.25, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5408196779418808", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Alt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.5, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 6.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 5.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [3.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.30166527902263685", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3.75, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.12696408098068068", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Alt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [10, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.38065481781807287", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Super",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11.25, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2956879186681016", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Fn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12.5, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9603069867409086", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Control",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13.75, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4419873725907111", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN21", "text": "Left"}]],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5689915518043194", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN21", "text": "Down"}]],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7203343328599159", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN21",
                                    "text": "Right"
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.25, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 2, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5405203865687231", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "0"}]],
                        "keycodeAffinity": []
                    },
                    "position": [18.5, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8242950296202667", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "."}]],
                        "keycodeAffinity": []
                    },
                    "position": [20.5, 5.25],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1337717154047262", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Esc",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 6.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.23725638423376139", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN21",
                                    "text": "~",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN21",
                                    "text": "`",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 6.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.26540939391242513", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "|",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "\\",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [14, 6.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.49706244489477003", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Delete",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 6.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7848007623798534", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "End",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 6.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.39140377374972646", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgDn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.25, 6.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.10449704067239418", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN21",
                                    "text": "|",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN21",
                                    "text": "\\",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12, 7.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3010807278929506", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Bksp",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13.5, 7.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.25327540515981783", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgUp",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 7.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 2, "width": 1.25, "offset": [0, 0]},
                            {"height": 1, "width": 1.5, "offset": [-0.25, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 90,
                                "length": 1,
                                "offset": [0.625, 0.5]
                            }
                        ],
                        "stem": [0.625, 1]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2328796639832178", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [18.75, 7.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 2, "width": 1.25, "offset": [0, 0]},
                            {"height": 1, "width": 1.5, "offset": [-0.25, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 90,
                                "length": 1,
                                "offset": [0.625, 0.5]
                            }
                        ],
                        "stem": [0.625, 1]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5623714871453984", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [20.25, 7.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4487471312911431", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN21", "text": "-"}]],
                        "keycodeAffinity": []
                    },
                    "position": [21.5, 7.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]},
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [{"height": 1, "width": 1.25, "offset": [0, 0]}],
                    "profile": {"profile": "0.5237539387349415", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Caps",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6623434263647681", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Control",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1.75, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.20122505542623204", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "F"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4.75, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8841705412388416", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "J"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7.75, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5829704791659733", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12.75, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.07519333267447936", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgDn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8458638631429438", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "|",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": "\\",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.75, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6709053965556062", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN21", "text": "+"}]],
                        "keycodeAffinity": []
                    },
                    "position": [21.5, 8.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 2, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3001427946672772", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4039273860142183", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9329439128716586", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "B"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6.25, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.75,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.375, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6925454404996294", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [7.25, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3197124070852766", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13.25, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.19338438710081762", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "End",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.580112094457991", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Fn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3296493223755095", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [17.75, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7606840144681855", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN20",
                                    "text": "<",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN20",
                                    "text": ">",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [19, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 2, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 90,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [0.5, 1]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3543181179175656", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [21.5, 9.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3340015176399054", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Control",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1516028838141057", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Super",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1.5, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.31118264650284355", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Alt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.5, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 7, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 6,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [3.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.24804643825533024", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6128322985986856", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Alt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [11, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7845441602600565", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Super",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [12.5, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7182855474908711", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Control",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [13.5, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.46991384377557677", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Ctrl",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [15.25, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7220215161719985", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Alt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [16.25, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6798353710108838", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "0"}]],
                        "keycodeAffinity": []
                    },
                    "position": [20.5, 10.75],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.29694516738798504",
                "productID": "0.23953909587966837"
            },
            "name": "addition",
            "keys": [
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7019809732431361", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "F13",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.45370477264985065", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgUp",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.16152404937242149", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Home",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]},
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [{"height": 1, "width": 1.25, "offset": [0, 0]}],
                    "profile": {"profile": "0.27450407819121114", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Ctrl",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.13256827693030648", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Function",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3.75, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.30125524602268516", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "PgDn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6629241431469179", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Alt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 2, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.47233612660968016", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2389235405501029", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4118335455075077", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Super",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5430909243001452", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Super",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1.5, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.32463725725928017", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Function",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5152090024052742", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4.5, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4122734933434413", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [5.75, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7581128070553882", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [6.75, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7527984879822898", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [8, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 3, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 2,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.343931597976161", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 3, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 2,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1500440532500229", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 6, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 5,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [3, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5897626767841695", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [6, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 3, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 2,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.036241617906168866", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 3, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 2,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.733274118993188", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 6, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 5,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [3, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.759762220345497", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [6, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.7375775417114554",
                "productID": "0.7552739058197109"
            },
            "name": "macro",
            "keys": [
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3829681151615565", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M1",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.31852256777445387", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M2",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4140272561874827", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M1",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2822697685147204", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M3",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9203288304377042", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M4",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.34408986373167716", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M2",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.40642021388259764", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M5",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.29731279425648904", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M6",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.17580581134479623", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M3",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6214089384479533", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M7",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6801092755318263", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M8",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.44694188395410994", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M4",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7892708883094506", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M9",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6513000870411827", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M10",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.575487375210568", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "M5",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.35482633672306774",
                "productID": "0.20298938756469287"
            },
            "name": "novelties",
            "keys": [
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.37102147088271953", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9125732740763077", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.027941785873390357", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6087644647029451", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5933357528781027", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.14002694930956205", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.20848792855981446", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9916200541729108", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.07018159024794879", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8519730820393796", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1145583199074145", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4.25, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3867221643613672", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8239146083354181", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.45278760884971314", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.24407167709223265", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3.25, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3278124474331481", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5531910509110112", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8068902571219123", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.32009411742206195", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3.5, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.6701103273936293",
                "productID": "0.46827175245726305"
            },
            "name": "international",
            "keys": [
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8655760601824758", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.006474996508024633", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8599739075163124", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7952553136759426", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5050719487461841", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7268465686557282", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3261465319095833", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.24871287810511133", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.44938077026628154", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.012416399805525558", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9348179844512681", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4411675466939087", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.22811682791133947", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [8, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8144574882861235", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [9, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5404624197369683", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.32517564757549633", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7020128270714325", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7010387573365906", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9558960145618856", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.09259346894174447", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6165677899675628", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4627320748232413", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.35346957383165756", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [8, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3941033226046933", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [9, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4043876347621027", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [10, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7961041669686615", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.21486492235587207", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.13656578875383785", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.035355700633788345", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8549861838557831", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6914679272616613", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1675997083142453", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5454881267042679", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [0, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6470840000071132", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5854867379533917", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7837116685194767", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.638273715112404", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6444560062365123", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5423589410620238", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9991159224391599", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7, 4],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9824258753187611", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [0, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5095873503926189", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [1, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.16964284451677547", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [2, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.36921369638999657", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.28754928543197744", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9187568408300701", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1553428794353371", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.30304533307787196", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "TBD"}]],
                        "keycodeAffinity": []
                    },
                    "position": [7, 5],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6970765405044581", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "AltGr",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 6],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.787029291789203", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "AltGr",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 6],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9843503837310366", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "AltGr",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2.25, 6],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.28216356001413145",
                "productID": "0.4373092787861692"
            },
            "name": "aesthetic",
            "keys": [
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 6.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 5.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [3.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6752924853266042", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 7, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 6,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [3.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4922768921480212", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 2, "offset": [0, 0]}],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7897806283823838", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.25,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.125, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7238123479207117", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 2.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [
                            {
                                "angle": 0,
                                "length": 1.75,
                                "offset": [0.5, 0.5]
                            }
                        ],
                        "stem": [1.375, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.37014343479920275", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4.25, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8600824156971205", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4240105070136244", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6146157001831207", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.34101243622791344", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Left"}]],
                        "keycodeAffinity": []
                    },
                    "position": [3.25, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7434156482558683", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Up"}]],
                        "keycodeAffinity": []
                    },
                    "position": [4.25, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.35360738057827046", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Down"}]],
                        "keycodeAffinity": []
                    },
                    "position": [5.25, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.16254947208445292", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [[{"color": "GN20", "text": "Right"}]],
                        "keycodeAffinity": []
                    },
                    "position": [6.25, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.30609536930315406",
                "productID": "0.4132574399620006"
            },
            "name": "obscure",
            "keys": [
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.25496106525566176", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Esc",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9694771763896477", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Esc",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.443398211573937", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Tab",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9291943779440317", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Back",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3844659320042232", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Backspace",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6132041305704719", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Esc",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3864224685717341", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Esc",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7493597575231841", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [
                                {
                                    "color": "GN21",
                                    "text": "\"",
                                    "size": 0.80001
                                }
                            ],
                            [
                                {
                                    "color": "GN21",
                                    "text": "'",
                                    "size": 0.80001
                                }
                            ]
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.30778331492923994", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Tab",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.23753759908835992", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Tab",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8842867093135607", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [5.25, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4794617463225037", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [6.25, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6393033542984938", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [7.25, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9901813438255673", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [9, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.1352269036214122", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Fn",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [10.75, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7745131373606957", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Shift",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9477882489926992", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Hyper",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.869058837471183", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.10308792755505025", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN20",
                                    "text": "Enter",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.967763924808152", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Lower",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.7425327691204193", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Raise",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [5, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9938760659557411", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Lock",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [6, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8316734528937451", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [0, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6668246389366757", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [1, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.46805263010898", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [2, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GR21",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.8999222791870365", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [3.75, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9183970068977092", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [4.75, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2311005845334677", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [],
                        "keycodeAffinity": []
                    },
                    "position": [5.75, 3],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "botanical-beige",
                    "keycodeAffinity": []
                }
            ]
        },
        {
            "id": {
                "vendorID": "0.182450819529826",
                "productID": "0.867740229005932"
            },
            "name": "command",
            "keys": [
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.875, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.4082688642956265", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Command",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]},
                            {"height": 1, "width": 1.75, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [{"height": 1, "width": 1.25, "offset": [0, 0]}],
                    "profile": {"profile": "0.8829814540814032", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Cmd",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1.75, 0],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.6166044582329502", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Opt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.39639807988850784", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Opt",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.05008112846181034", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Option",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.096393399415873", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Option",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3.25, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {
                        "profile": "0.0019606449629143707",
                        "row": "R1"
                    },
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Option",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4.5, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.2633923717091904", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Option",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [6, 1],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.5276174270477365", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Cmd",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [0, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [{"height": 1, "width": 1, "offset": [0, 0]}],
                        "stabilizers": [],
                        "stem": [0.5, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9555115927365367", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Cmd",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [1, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.3613721351418653", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Cmd",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [2, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.25, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.625, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9154985259533719", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Cmd",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [3.25, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.021745407629615743", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Command",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [4.5, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                },
                {
                    "blank": {
                        "boxes": [
                            {"height": 1, "width": 1.5, "offset": [0, 0]}
                        ],
                        "stabilizers": [],
                        "stem": [0.75, 0.5]
                    },
                    "shelf": [],
                    "profile": {"profile": "0.9022625727627518", "row": "R1"},
                    "legend": {
                        "frontLegends": [],
                        "topLegends": [
                            [],
                            [
                                {
                                    "color": "GN21",
                                    "text": "Command",
                                    "size": 0.70001
                                }
                            ],
                            []
                        ],
                        "keycodeAffinity": []
                    },
                    "position": [6, 2],
                    "barred": false,
                    "scooped": false,
                    "stem": "Cherry",
                    "color": "GN20",
                    "keycodeAffinity": []
                }
            ]
        }
    ]
}
