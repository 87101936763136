[
    {
        "name": "CandyBar"
    },
    [
        {
            "t": "#ff0000\n\n\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F7\n\n\n\n\n\n\n\n\n7",
        "F8\n\n\n\n\n\n\n\n\n8",
        "F9\n\n\n\n\n\n\n\n\n9",
        {
            "t": "#111111",
            "a": 7,
            "f": 3
        },
        "Esc",
        {
            "f": 2,
            "fa": [3]
        },
        "Tab",
        "Q",
        "W",
        {
            "t": "#111111\n\n#ff0000",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\n!\n\n\n\n\n\n\nE",
        {
            "t": "#111111",
            "a": 7
        },
        "R",
        "T",
        "Y",
        "U",
        "I",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Up\n\n\n\n\n\n\n\n\nO",
        {
            "t": "#111111\n\n#ff0000"
        },
        "\n\n%\n\n\n\n\n\n\nP",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Bsp",
        "Del"
    ],
    [
        {
            "t": "#ff0000\n\n\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F4\n\n\n\n\n\n\n\n\n4",
        {
            "n": true
        },
        "F5\n\n\n\n\n\n\n\n\n5",
        "F6\n\n\n\n\n\n\n\n\n6",
        {
            "t": "#111111",
            "a": 7,
            "f": 3
        },
        "(",
        {
            "c": "#cdcdcd",
            "a": 4,
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nCtrl",
        {
            "c": "#cccccc",
            "t": "#111111\n\n#ff0000"
        },
        "\n\n@\n\n\n\n\n\n\nA",
        "\n\n*\n\n\n\n\n\n\nS",
        "\n\n$\n\n\n\n\n\n\nD",
        {
            "t": "#111111",
            "a": 7,
            "fa": [3],
            "n": true
        },
        "F",
        "G",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "Home\n\n#\n\n\n\n\n\n\nH",
        {
            "t": "#111111\n\n#ff0000\n\n\n\n\n#ff0000",
            "n": true
        },
        "\n\n-\n\n\n\n\n_\n\nJ",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111"
        },
        "Left\n\n=\n\n\n\n\n+\n\nK",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111"
        },
        "Right\n\n\\\n\n\n\n\n|\n\nL",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000"
        },
        "\n\n`\n\n\n\n\n~\n\n;\n:",
        {
            "t": "#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\n'\n\""
    ],
    [
        {
            "t": "#ff0000\n\n\n\n\n\n\n\n\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F1\n\n\n\n\n\n\n\n\n1",
        "F2\n\n\n\n\n\n\n\n\n2",
        "F3\n\n\n\n\n\n\n\n\n3",
        {
            "t": "#111111",
            "a": 7,
            "f": 3
        },
        ")",
        {
            "c": "#cdcdcd",
            "a": 4,
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
            "w": 1.75
        },
        "\n\n\n1.75\n\n\n\n\n\nShift",
        {
            "c": "#cccccc",
            "a": 7,
            "fa": [3]
        },
        "Z",
        "X",
        {
            "t": "#111111\n\n#ff0000",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\n^\n\n\n\n\n\n\nC",
        {
            "t": "#111111",
            "a": 7
        },
        "V",
        "B",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "End\n\n&\n\n\n\n\n\n\nN",
        {
            "t": "#111111\n\n#ff0000\n\n\n\n\n#ff0000"
        },
        "\n\n[\n\n\n\n\n{\n\nM",
        {
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n#ff0000\n\n#111111\n#111111"
        },
        "Down\n\n]\n\n\n\n\n}\n\n,\n<",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n>\n\n\n\n\n.",
        {
            "t": "#0100ff\n#111111\n\n\n\n\n#111111"
        },
        "\n?\n\n\n\n\n/",
        {
            "c": "#8c94ff",
            "t": "#111111",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3],
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nLower"
    ],
    [
        {
            "c": "#cccccc",
            "t": "#ff0000\n\n\n#111111\n\n\n\n\n\n#111111",
            "w": 2
        },
        "F10\n\n\n2\n\n\n\n\n\n0",
        {
            "t": "#ff0000"
        },
        "F11",
        "F12",
        {
            "c": "#ff7575",
            "t": "#111111",
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nRaise",
        {
            "c": "#cdcdcd",
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nSuper",
        {
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nAlt",
        {
            "w": 2.75
        },
        "\n\n\n2.75\n\n\n\n\n\nSpace",
        {
            "w": 2.25
        },
        "\n\n\n2.25\n\n\n\n\n\nEnter",
        {
            "c": "#ff7575",
            "w": 1.25
        },
        "\n\n\n1.25\n\n\n\n\n\nRaise",
        {
            "c": "#cccccc",
            "a": 7
        },
        "",
        "",
        ""
    ]
]
