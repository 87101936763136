{
  "name": "Titan 60",
  "vendorId": "0xACE1",
  "productId": "0x5449",
  "firmwareVersion": 0,
  "keycodes": ["via/keycodes", "via/qmk_lighting"],
  "menus": [
    "via/keymap",
    "via/layouts",
    "via/macros",
    "via/save_load",
    "core/qmk_backlight",
    "core/qmk_rgblight"
  ],
  "matrix": {"rows": 5, "cols": 14},
  "layouts": {
    "labels": [
      "Split Backspace",
      "ISO Enter",
      [
        "Row 4",
        "Default",
        "Utilitarian",
        "Split right shift",
        "Split left shift",
        "Split both shifts"
      ],
      ["Bottom Row", "6.25U", "7U Tsangan", "Utilitarian"]
    ],
    "keymap": [
      [{"x": 13, "c": "#aaaaaa"}, "0,13\n\n\n0,1", "2,12\n\n\n0,1"],
      [
        {"c": "#cccccc"},
        "0,0",
        "0,1",
        "0,2",
        "0,3",
        "0,4",
        "0,5",
        "0,6",
        "0,7",
        "0,8",
        "0,9",
        "0,10",
        "0,11",
        "0,12",
        {"c": "#aaaaaa", "w": 2},
        "0,13\n\n\n0,0"
      ],
      [
        {"w": 1.5},
        "1,0",
        {"c": "#cccccc"},
        "1,1",
        "1,2",
        "1,3",
        "1,4",
        "1,5",
        "1,6",
        "1,7",
        "1,8",
        "1,9",
        "1,10",
        "1,11",
        "1,12",
        {"c": "#aaaaaa", "w": 1.5},
        "1,13\n\n\n1,0",
        {"x": 1.5, "w": 1.25, "h": 2, "w2": 1.5, "h2": 1, "x2": -0.25},
        "2,13\n\n\n1,1"
      ],
      [
        {"w": 1.75},
        "2,0",
        {"c": "#cccccc"},
        "2,1",
        "2,2",
        "2,3",
        "2,4",
        "2,5",
        "2,6",
        "2,7",
        "2,8",
        "2,9",
        "2,10",
        "2,11",
        {"c": "#aaaaaa", "w": 2.25},
        "2,13\n\n\n1,0",
        {"x": 0.5, "c": "#cccccc"},
        "1,13\n\n\n1,1"
      ],
      [
        {"c": "#aaaaaa", "w": 2.25},
        "3,0\n\n\n2,0",
        {"c": "#cccccc"},
        "3,2\n\n\n2,0",
        "3,3\n\n\n2,0",
        "3,4\n\n\n2,0",
        "3,5\n\n\n2,0",
        "3,6\n\n\n2,0",
        "3,7\n\n\n2,0",
        "3,8\n\n\n2,0",
        "3,9\n\n\n2,0",
        "3,10\n\n\n2,0",
        "3,11\n\n\n2,0",
        {"c": "#aaaaaa", "w": 2.75},
        "3,12\n\n\n2,0"
      ],
      [
        {"w": 1.25},
        "4,0\n\n\n3,0",
        {"w": 1.25},
        "4,1\n\n\n3,0",
        {"w": 1.25},
        "4,2\n\n\n3,0",
        {"c": "#cccccc", "w": 6.25},
        "4,6\n\n\n3,0",
        {"c": "#aaaaaa", "w": 1.25},
        "4,10\n\n\n3,0",
        {"w": 1.25},
        "4,11\n\n\n3,0",
        {"w": 1.25},
        "4,12\n\n\n3,0",
        {"w": 1.25},
        "4,13\n\n\n3,0"
      ],
      [
        {"y": 0.5, "w": 2},
        "3,0\n\n\n2,1",
        {"c": "#cccccc"},
        "3,2\n\n\n2,1",
        "3,3\n\n\n2,1",
        "3,4\n\n\n2,1",
        "3,5\n\n\n2,1",
        "3,6\n\n\n2,1",
        "3,7\n\n\n2,1",
        "3,8\n\n\n2,1",
        "3,9\n\n\n2,1",
        "3,10\n\n\n2,1",
        "3,11\n\n\n2,1",
        {"c": "#aaaaaa"},
        "3,13\n\n\n2,1",
        "3,12\n\n\n2,1",
        "4,3\n\n\n2,1"
      ],
      [
        {"w": 2.25},
        "3,0\n\n\n2,2",
        {"c": "#cccccc"},
        "3,2\n\n\n2,2",
        "3,3\n\n\n2,2",
        "3,4\n\n\n2,2",
        "3,5\n\n\n2,2",
        "3,6\n\n\n2,2",
        "3,7\n\n\n2,2",
        "3,8\n\n\n2,2",
        "3,9\n\n\n2,2",
        "3,10\n\n\n2,2",
        "3,11\n\n\n2,2",
        {"c": "#aaaaaa", "w": 1.75},
        "3,12\n\n\n2,2",
        "4,3\n\n\n2,2"
      ],
      [
        {"w": 1.25},
        "3,0\n\n\n2,3",
        {"c": "#cccccc"},
        "3,1\n\n\n2,3",
        "3,2\n\n\n2,3",
        "3,3\n\n\n2,3",
        "3,4\n\n\n2,3",
        "3,5\n\n\n2,3",
        "3,6\n\n\n2,3",
        "3,7\n\n\n2,3",
        "3,8\n\n\n2,3",
        "3,9\n\n\n2,3",
        "3,10\n\n\n2,3",
        "3,11\n\n\n2,3",
        {"c": "#aaaaaa", "w": 2.75},
        "3,12\n\n\n2,3"
      ],
      [
        {"w": 1.25},
        "3,0\n\n\n2,4",
        {"c": "#cccccc"},
        "3,1\n\n\n2,4",
        "3,2\n\n\n2,4",
        "3,3\n\n\n2,4",
        "3,4\n\n\n2,4",
        "3,5\n\n\n2,4",
        "3,6\n\n\n2,4",
        "3,7\n\n\n2,4",
        "3,8\n\n\n2,4",
        "3,9\n\n\n2,4",
        "3,10\n\n\n2,4",
        "3,11\n\n\n2,4",
        {"c": "#aaaaaa", "w": 1.75},
        "3,12\n\n\n2,4",
        "4,3\n\n\n2,4"
      ],
      [
        {"y": 0.5, "w": 1.5},
        "4,0\n\n\n3,1",
        "4,1\n\n\n3,1",
        {"w": 1.5},
        "4,2\n\n\n3,1",
        {"c": "#cccccc", "w": 7},
        "4,6\n\n\n3,1",
        {"c": "#aaaaaa", "w": 1.5},
        "4,11\n\n\n3,1",
        "4,12\n\n\n3,1",
        {"w": 1.5},
        "4,13\n\n\n3,1"
      ],
      [
        {"w": 1.25},
        "4,0\n\n\n3,2",
        {"w": 1.25},
        "4,1\n\n\n3,2",
        {"w": 1.25},
        "4,2\n\n\n3,2",
        {"c": "#cccccc", "w": 2.75},
        "4,4\n\n\n3,2",
        {"w": 1.25},
        "4,6\n\n\n3,2",
        {"w": 2.25},
        "4,8\n\n\n3,2",
        {"c": "#aaaaaa"},
        "4,10\n\n\n3,2",
        "4,11\n\n\n3,2",
        "4,9\n\n\n3,2",
        "4,12\n\n\n3,2",
        "4,13\n\n\n3,2"
      ]
    ]
  }
}