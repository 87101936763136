[
    {
        "name": "hatsu"
    },
    [
        {
            "x": 3,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111",
            "f": 2,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "3\n\n\n\n\n\n#\n\n\nE",
        {
            "x": 7.75
        },
        "8\n\n\n\n\n\n*\n\n\nI"
    ],
    [
        {
            "y": -0.9,
            "x": 2
        },
        "2\n\n\n\n\n\n@\n\n\nW"
    ],
    [
        {
            "y": -1,
            "x": 12.75,
            "t": "#0100ff\n\n\n#ff0000\n\n\n\n\n\n#111111"
        },
        "9\n\n\n3s RST\n\n\n(\n\n\nO"
    ],
    [
        {
            "y": -0.9200000000000002,
            "x": 4,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "4\n\n\n\n\n\n$\n\n\nR",
        {
            "x": 5.75
        },
        "7\n\n\n\n\n\n&\n\n\nU"
    ],
    [
        {
            "y": -0.98,
            "x": 5
        },
        "5\n\n\n\n\n\n%\n\n\nT",
        {
            "x": 3.75
        },
        "6\n\n\n\n\n\n^\n\n\nY"
    ],
    [
        {
            "y": -0.37,
            "t": "#0100ff\n\n#ff0000\n#ff0000\n\n\n\n\n\n#111111"
        },
        "Esc\n\nOn\n5s Off\n\n\n\n\n\nTab",
        {
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "1\n\n\n\n\n\n!\n\n\nQ",
        {
            "x": 11.75,
            "t": "#0100ff\n\n#ff0000\n\n\n\n\n\n\n#111111"
        },
        "0\n\nBAT\n\n\n\n)\n\n\nP",
        "Del\n\nOn\n\n\n\n\n\n\nBsp"
    ],
    [
        {
            "y": -0.8300000000000001,
            "x": 3,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "F3\n\n\n\n\n\n\n\n\nD",
        {
            "x": 7.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "=\n\n\n\n\n\n+\n\n\nK"
    ],
    [
        {
            "y": -0.8999999999999999,
            "x": 2,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "F2\n\n\n\n\n\n\n\n\nS",
        {
            "x": 9.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "\\\n\n\n\n\n\n|\n\n\nL"
    ],
    [
        {
            "y": -0.9200000000000002,
            "x": 4,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "F4\n\n\n\n\n\n\n\n\nF",
        {
            "x": 5.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "-\n\n\n\n\n\n_\n\n\nJ"
    ],
    [
        {
            "y": -0.9799999999999998,
            "x": 5,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "F5\n\n\n\n\n\n\n\n\nG",
        {
            "x": 3.75,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "H"
    ],
    [
        {
            "y": -0.3700000000000001,
            "c": "#cdcdcd",
            "t": "#111111\n\n#ff0000\n#ff0000",
            "a": 4,
            "fa": [3, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "\n\nBT1\n3s BT1\n\n\n\n\n\nCtrl",
        {
            "c": "#cccccc",
            "t": "#0100ff\n\n#ff0000\n#ff0000\n\n\n\n\n\n#000000",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F1\n\nBT2\n3s BT2\n\n\n\n\n\nA",
        {
            "x": 11.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "`\n\n\n\n\n\n~\n\n\n;\n:",
        {
            "t": "#111111",
            "a": 5,
            "fa": [0, 0, 0, 0, 0, 0, 3]
        },
        "\n\"\n\n\n\n\n'"
    ],
    [
        {
            "y": -0.8300000000000001,
            "x": 3,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 3, 0, 0, 3]
        },
        "F8\n\n\n\n\n\n\n\n\nC",
        {
            "x": 7.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111",
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "]\n\n\n\n\n\n}\n\n\n,\n<"
    ],
    [
        {
            "y": -0.8999999999999999,
            "x": 2,
            "t": "#0100ff\n\n#ff0000\n#ff0000\n\n\n\n\n\n#000000"
        },
        "F7\n\nBT3\n3s BT3\n\n\n\n\n\nX",
        {
            "x": 9.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Up\n\n\n\n\n\n\n\n\n.\n>"
    ],
    [
        {
            "y": -0.9199999999999999,
            "x": 4,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "F9\n\n\n\n\n\n\n\n\nV",
        {
            "x": 5.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111"
        },
        "[\n\n\n\n\n\n{\n\n\nM"
    ],
    [
        {
            "y": -0.98,
            "x": 5,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000"
        },
        "F10\n\n\n\n\n\n\n\n\nB",
        {
            "x": 3.75,
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "N"
    ],
    [
        {
            "y": -0.3700000000000001,
            "c": "#cdcdcd"
        },
        "Shift",
        {
            "c": "#cccccc",
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#000000",
            "a": 4,
            "fa": [0, 0, 0, 0, 0, 0, 0, 0, 0, 3]
        },
        "F6\n\n\n\n\n\n\n\n\nZ",
        {
            "x": 11.75,
            "t": "#0100ff\n\n\n\n\n\n\n\n\n#111111\n#111111"
        },
        "Right\n\n\n\n\n\n\n\n\n/\n?",
        {
            "c": "#8c94ff",
            "t": "#111111",
            "a": 7,
            "fa": [3]
        },
        "Fn"
    ],
    [
        {
            "y": -0.8500000000000001,
            "x": 3,
            "c": "#cccccc",
            "t": "#0100ff",
            "a": 4,
            "f": 2
        },
        "F12"
    ],
    [
        {
            "y": -0.98,
            "x": 11.75,
            "f": 2
        },
        "Left"
    ],
    [
        {
            "y": -0.9199999999999999,
            "x": 12.75,
            "f": 2
        },
        "Down"
    ],
    [
        {
            "c": "#cccccc",
            "y": -0.98,
            "x": 2,
            "f": 2
        },
        "F11"
    ],
    [
        {
            "c": "#cdcdcd",
            "r": 25,
            "y": -3.4499999999999997,
            "x": 10.1,
            "t": "#111111",
            "a": 7,
            "f": 3
        },
        "Super"
    ],
    [
        {
            "y": -0.9999999999999999,
            "x": 11.1,
            "f": 2
        },
        ""
    ],
    [
        {
            "y": -0.6000000000000001,
            "x": 5.6,
            "c": "#8c94ff",
            "f": 3,
            "h": 1.5
        },
        "Fn"
    ],
    [
        {
            "y": -0.9999999999999998,
            "x": 6.6,
            "c": "#cdcdcd",
            "h": 1.25
        },
        "Space"
    ],
    [
        {
            "y": -0.3999999999999999,
            "x": 10.1,
            "f": 2
        },
        "",
        {
            "c": "#ff7575",
            "f": 3
        },
        "Meta"
    ],
    [
        {
            "r": -25,
            "y": 4.65,
            "x": 3.15,
            "c": "#cdcdcd"
        },
        "Alt",
        {
            "x": -2.0000000000000004,
            "f": 2
        },
        ""
    ],
    [
        {
            "y": -0.6000000000000005,
            "x": 6.7,
            "f": 3,
            "h": 1.25
        },
        "Enter",
        {
            "c": "#8c94ff",
            "h": 1.5
        },
        "Fn"
    ],
    [
        {
            "y": -0.3999999999999986,
            "x": 2.15,
            "c": "#ff7575"
        },
        "Meta",
        {
            "c": "#cdcdcd",
            "f": 2
        },
        ""
    ]
]
